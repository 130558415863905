import { Injectable } from '@angular/core';
import { Alert } from '../models/alert';
import { AlertPage } from '../models/alert-page';
import { AlertDtoPagedResponse } from '../../common/models/data-contracts';
import { DatePipe } from '@angular/common';
import { AlertStatus } from '../models/alert-status';
import { LocaleService } from '../../common/services/locale.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsMapService {
  private datePipe: DatePipe;

  constructor(localeService: LocaleService) {
    this.datePipe = new DatePipe(localeService.currentLocale);
  }

  public mapAlertDtoPagedResponse(data: AlertDtoPagedResponse): AlertPage {
    const result = new AlertPage();
    result.nextPageToken = data.nextPageToken;
    result.items = new Array<Alert>();
    data.items.forEach(el => {
      const alert = new Alert();
      alert.id = el.id;
      alert.seen = el.seen ?? false;
      alert.componentType = el.component?.type.id;
      alert.componentName = el.component?.name;
      alert.severity = el.severity;
      alert.message = el.message;
      alert.date = this.datePipe.transform(el.timestamp, 'dd.MM.yyyy');
      alert.time = this.datePipe.transform(el.timestamp, 'HH:mm:ss');
      alert.fullTime = el.timestamp;
      alert.status = el.resolved
        ? AlertStatus.Resolved
        : AlertStatus.Unresolved;
      result.items.push(alert);
    });
    return result;
  }
}
