import { EventEmitter, Injectable, Output } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { UserService } from '../../common/services/user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertsApiService } from './alerts-api.service';
import { AlertsMapService } from './alerts-map.service';
import {
  AlertScope,
  AlertsIdDto,
  DeviceDetailsDto,
} from '../../common/models/data-contracts';
import { Alert } from '../models/alert';
import {
  PeriodDataNameInfo,
  DateTimeRange,
} from '../../common/classes/data-charts';
import { Subject } from 'rxjs/internal/Subject';
import { AlertStatus } from '../models/alert-status';

@Injectable({
  providedIn: 'root',
})
export class AlertsTableService {
  private destroy$: Subject<void> = null;
  private energyOperatorId: string;
  private energyCenterId: string;
  private deviceDetails: DeviceDetailsDto;
  public hideComponentColumn: boolean;
  private loading = true;
  private timePeriod: DateTimeRange;
  private nextPageToken: string = null;

  public source: LocalDataSource;

  public isLoading(): boolean {
    return this.loading;
  }

  public canLoadMore(): boolean {
    return this.nextPageToken && true;
  }

  @Output() alertSeenStateChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() alertStatusChanged: EventEmitter<AlertStatus> = new EventEmitter();

  constructor(
    private userService: UserService,
    private alertsApiService: AlertsApiService,
    private mapService: AlertsMapService
  ) {
    this.source = new LocalDataSource([]);
  }

  init(
    ownerComponentDestroy: Subject<void>,
    deviceDetails?: DeviceDetailsDto,
    settings?: { hideComponentColumn: boolean }
  ) {
    this.destroy$ = ownerComponentDestroy;
    this.deviceDetails = deviceDetails;
    this.hideComponentColumn = settings?.hideComponentColumn ?? false;
    this.userService.currentEnergyCenter$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(energyCenterContext => !!energyCenterContext))
      .subscribe(energyCenterContext => {
        this.energyOperatorId = energyCenterContext.community.energyOperator.id;
        this.energyCenterId = energyCenterContext.id;
      });
  }

  setTimePeriod(timePeriodInfo: PeriodDataNameInfo) {
    this.timePeriod = timePeriodInfo.period;
  }

  updateAndRefreshRow(rowDataToUpdate: Alert, newRowData: Alert) {
    this.source.update(rowDataToUpdate, newRowData).then();
  }

  public loadNextPage(first = false): void {
    this.loading = true;

    if (first) {
      this.source.load([]).then();
      this.nextPageToken = null;
    } else if (!this.nextPageToken) {
      this.loading = false;
      return;
    }

    this.alertsApiService
      .alertList(
        this.energyOperatorId,
        !this.deviceDetails ? AlertScope.Value1 : null,
        !this.deviceDetails ? this.energyCenterId : null,
        this.deviceDetails ? this.deviceDetails.id : null,
        this.timePeriod.startDate,
        this.timePeriod.endDate,
        null,
        10,
        this.nextPageToken
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        const page = this.mapService.mapAlertDtoPagedResponse(res);
        this.nextPageToken = page.nextPageToken;

        page.items.forEach(a => {
          this.source.append(a).then();
        });

        this.loading = false;
      });
  }

  public markAlertAsSeen(alert: Alert): void {
    const ids: AlertsIdDto = {
      alertIds: [alert.id],
    };
    this.loading = true;
    this.alertsApiService
      .markAlertAsSeen(this.energyOperatorId, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateAndRefreshRow(alert, { ...alert, seen: true });
        this.loading = false;
        this.alertSeenStateChanged.next(true);
      });
  }

  public markAlertAsUnseen(alert: Alert): void {
    const ids: AlertsIdDto = {
      alertIds: [alert.id],
    };
    this.loading = true;
    this.alertsApiService
      .markAlertAsUnseen(this.energyOperatorId, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateAndRefreshRow(alert, { ...alert, seen: false });
        this.loading = false;
        this.alertSeenStateChanged.next(false);
      });
  }

  public markAlertAsResolved(alert: Alert): void {
    const ids: AlertsIdDto = {
      alertIds: [alert.id],
    };
    this.loading = true;
    this.alertsApiService
      .markAlertAsResolved(this.energyOperatorId, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateAndRefreshRow(alert, {
          ...alert,
          status: AlertStatus.Resolved,
          seen: true,
        });
        this.loading = false;
        this.alertStatusChanged.next(AlertStatus.Resolved);
      });
  }

  public markAlertAsUnresolved(alert: Alert): void {
    const ids: AlertsIdDto = {
      alertIds: [alert.id],
    };
    this.loading = true;
    this.alertsApiService
      .markAlertAsUnresolved(this.energyOperatorId, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateAndRefreshRow(alert, {
          ...alert,
          status: AlertStatus.Unresolved,
        });
        this.loading = false;
        this.alertStatusChanged.next(AlertStatus.Unresolved);
      });
  }
}
