<nb-button-group (valueChange)="updatePeriodGroupValue($event)">
  <ng-container *ngFor="let button of timeMenu">
    <button
      nbButtonToggle
      *ngIf="button !== periodTab.entireTime || showEntireTime"
      [ngClass]="{ active: periodGroupValue === button }"
      (click)="clickMenu(button)"
      value="{{ button }}">
      {{ 'timePeriod.' + button | translate }}
    </button>
  </ng-container>
</nb-button-group>
