<div class="form-field-wrap" [ngClass]="class">
  <div class="form-field-label simple-text secondary font-size-small bold">
    {{ label }}
  </div>
  <div class="form-field-content simple-text font-size-normal" #explicitContent>
    <ng-content />
  </div>
  <div
    class="form-field-content simple-text font-size-normal"
    *ngIf="!explicitContent.childElementCount">
    {{ content || '—' }}
  </div>
</div>
