<div
  class="form-field-wrap"
  [class.has-error]="formHelper.hasError(attachedControl)"
  [ngClass]="class">
  <label>
    <div class="form-field-label simple-text secondary font-size-small bold">
      <span>{{ label }}</span>
      <span *ngIf="isRequired" class="required">*</span>
    </div>
    <div class="form-field-content">
      <ng-content />
    </div>
  </label>
  <app-error-input
    *ngIf="!errorInfoTemplate"
    class="form-field-error-info"
    [formElement]="attachedControl" />
  <div *ngIf="errorInfoTemplate" class="form-field-error-info">
    <ng-container
      *ngTemplateOutlet="
        errorInfoTemplate;
        context: errorInfoTemplateContext
      " />
  </div>
</div>
