<div class="consprod-wrap">
  <div class="inner" [ngClass]="{ empty: isEmpty(renderValue) }">
    <span class="icon-type" [inlineSVG]="iconType()"></span>
    <span>{{ renderValue | number: '1.0-2' | nullDash }} kW</span>
  </div>
  <div class="inner" [ngClass]="{ empty: isEmpty(paramProductionValue) }">
    <span class="icon-type" [inlineSVG]="iconType()"></span>
    <span>{{ paramProductionValue | number: '1.0-2' | nullDash }} kW</span>
  </div>
</div>
