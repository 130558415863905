<div class="header-container">
  <div
    class="logo-container"
    [nbContextMenu]="communityMenuItems"
    [nbContextMenuTag]="'community-menu'">
    <a class="logo">
      <span
        class="logo-icon"
        aria-label="Logo icon"
        [inlineSVG]="'/assets/icons/logo.svg'"></span>
      <span>{{ communityName }}</span>
      <span
        *ngIf="communityMenuItems.length > 1"
        class="logo-dropdown"
        [inlineSVG]="'/assets/icons/dropdown.svg'"></span>
    </a>
  </div>
  <ul class="menu-items" #mainMenu>
    <li *ngFor="let item of items" class="menu-item">
      <a
        class="menu-item-link"
        [routerLink]="item.link"
        routerLinkActive="active"
        >{{ item.title | translate }}</a
      >
    </li>
  </ul>
</div>
<div
  class="burger-menu"
  (appClickOutside)="onOutsideMenu()"
  (click)="openMenuMobile()"></div>
<div
  class="header-container"
  [nbContextMenu]="userMenu"
  [nbContextMenuTag]="'user-menu'">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        [onlyPicture]="true"
        [name]="user?.fullName"
        [color]="'rgba(0, 189, 199, 0.12)'"
        [picture]="null">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
