import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceUnit',
})
export class SpaceUnitPipe implements PipeTransform {
  // use in html
  transform(value: string): string {
    if (value !== '°C') {
      return `\xa0${value}`;
    } else {
      return value;
    }
  }
}
