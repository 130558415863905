import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '../../../../common/classes/base-component';
import { CheckBoxOptions } from '../../../../common/classes/data-charts';

@Component({
  selector: 'app-d3-chart-checkboxes',
  templateUrl: './d3-chart-checkboxes.component.html',
  styleUrls: ['./d3-chart-checkboxes.component.scss'],
})
export class D3ChartCheckboxesComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  @Input() checkboxOptions: CheckBoxOptions[];
  @Input() hideCommonValues?: boolean;
  @Input() arrayTwoInColumn?: string[][];

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('grid') grid: ElementRef;
  @ViewChild('optionsWrap') optionsWrap: ElementRef;

  @Output() switchCheckbox: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize', ['$event']) onResize() {
    if (this.arrayTwoInColumn) {
      this.optionsWrap.nativeElement.style.opacity = 0;
      this.startedCountCol--;
      this.arrayWidthCheckbox = [];
      this.checkTableWidth();
    }
  }

  public arrayWidthCheckbox: number[] = [];
  private startedCountCol = 2;

  ngAfterViewInit() {
    if (this.arrayTwoInColumn) {
      this.checkTableWidth();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkboxOptions) {
      if (this.arrayTwoInColumn) {
        this.checkboxAcceptDistricts();
      }
    }
  }

  private checkTableWidth() {
    setTimeout(() => {
      let columnCount;
      const gridCountElements = this.grid.nativeElement.children.length;
      if (
        this.wrapper.nativeElement.offsetWidth >
          this.grid.nativeElement.offsetWidth &&
        this.startedCountCol <= gridCountElements
      ) {
        this.startedCountCol++;
        columnCount = this.startedCountCol;
        this.grid.nativeElement.setAttribute(
          'style',
          `grid-template-columns: repeat(${columnCount}, max-content )`
        );
        this.checkTableWidth();
      } else {
        columnCount = this.startedCountCol - 1;
        this.grid.nativeElement.setAttribute(
          'style',
          `grid-template-columns: repeat(${columnCount}, max-content )`
        );
        for (let i = 0; i < columnCount; i++) {
          const rect =
            this.grid.nativeElement.children[i].getBoundingClientRect();
          this.arrayWidthCheckbox.push(rect.width);
        }
        this.optionsWrap.nativeElement.style.opacity = 1;
      }
    });
  }

  public disabledCheckbox(id: string): boolean {
    const shownElements = this.checkboxOptions.filter(el => el.shown);
    return shownElements.length === 1 && shownElements[0].id === id;
  }

  public switchPlot(id: string, event): void {
    this.switchCheckbox.emit({ id: id, event: event });
  }

  public checkboxFilterDistrict(items: string[]) {
    const elFiltered: any[] = [];
    items.forEach(el => {
      elFiltered.push(this.checkboxOptions.find(i => i.name === el));
    });
    return elFiltered;
  }

  public checkboxAcceptDistricts() {
    let arrayNeededName = [];
    this.checkboxOptions.forEach(el => {
      arrayNeededName.push(el.name);
    });
    let arrayTwoInColumnName = [];
    this.arrayTwoInColumn.forEach(array => {
      arrayTwoInColumnName = arrayTwoInColumnName.concat(array);
    });
    arrayTwoInColumnName.forEach(el => {
      arrayNeededName = arrayNeededName.filter(item => item !== el);
    });

    const arrayAcceptDistricts: any[] = [];
    arrayNeededName.forEach(el => {
      arrayAcceptDistricts.push(this.checkboxOptions.find(i => i.name === el));
    });
    return arrayAcceptDistricts;
  }
}
