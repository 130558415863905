<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body class="dialog-content-wrap">
    <div class="simple-text" *ngIf="message">{{ message }}</div>
    <div class="body-info" *ngIf="body" [innerHTML]="bodyTrusted"></div>
  </nb-card-body>
  <nb-card-footer>
    <button *ngIf="showCancel" nbButton (click)="dismiss()">
      {{ 'common.cancel' | translate }}
    </button>
    <button *ngIf="okText" nbButton status="primary" (click)="dismiss(true)">
      {{ okText }}
    </button>
    <button *ngIf="!okText" nbButton status="primary" (click)="dismiss(true)">
      {{ 'common.' + okAction | translate }}
    </button>
  </nb-card-footer>
</nb-card>
