import { Subject } from 'rxjs/internal/Subject';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  protected destroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
