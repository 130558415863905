import { Component, Input, OnInit } from '@angular/core';
import { NbCalendarRange, NbDateService, NbDialogRef } from '@nebular/theme';
import { PeriodTab } from '../../../common/constants/enum';
import { DateTimeRange } from '../../../common/classes/data-charts';

@Component({
  selector: 'app-modal-calendar-range',
  templateUrl: './modal-calendar-range.component.html',
  styleUrls: ['./modal-calendar-range.component.scss'],
})
export class ModalCalendarRangeComponent implements OnInit {
  @Input() periodGroupValue: PeriodTab;
  @Input() timePeriod: DateTimeRange;

  public periodTab = PeriodTab;
  public range: NbCalendarRange<Date>;
  public max: Date;

  constructor(
    protected dateService: NbDateService<Date>,
    protected ref: NbDialogRef<ModalCalendarRangeComponent>
  ) {
    this.range = {
      start: null,
      end: null,
    };
    this.max = dateService.today();
  }

  public ngOnInit(): void {
    if (this.periodGroupValue === this.periodTab.custom) {
      this.range = {
        start: new Date(this.timePeriod.startDate),
        end: new Date(this.timePeriod.endDate),
      };
    }
  }

  dismiss(save?: any): void {
    this.ref.close(save);
  }
}
