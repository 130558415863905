import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../constants/constants';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable()
export class InsertAuthTokenInterceptor implements HttpInterceptor {
  constructor(private appConfigService: AppConfigService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(
      this.appConfigService.getConfigUrl()
    );
    if (isApiUrl) {
      const userToken = localStorage.getItem(Constants.TOKEN_LS);
      const valueToken = JSON.parse(userToken);
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${valueToken.token}`
        ),
      });
    }
    return next.handle(request);
  }
}
