import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { nest } from 'd3-collection';
import { BaseComponent } from '../../../common/classes/base-component';
import {
  CheckBoxOptions,
  ChartDataPoint,
  DateTimeRange,
  DataPointsByUnit,
} from '../../../common/classes/data-charts';
import { DatePlot } from '../../../common/helpers/datePlot';
import { TooltipForPlotService } from '../../services/tooltip-for-plot.service';
import { TimeSeriesDataViewModel } from '../../../common/classes/telemetry-models';

@Component({
  selector: 'app-d3-chart',
  templateUrl: './d3-chart.component.html',
  styleUrls: ['./d3-chart.component.scss'],
})
export class D3ChartComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('svgContainer') svgContainer: ElementRef;

  @Input() telemetry: TimeSeriesDataViewModel[];
  @Input() dateTimeRange: DateTimeRange;

  @Input() hideCommonValues?: boolean;
  @Input() arrayTwoInColumn?: string[][];

  @Input() scaleFactor = 0.5;
  @Input() keepSpaceForYAxis = 0;

  public checkboxOptions: CheckBoxOptions[] = [];
  private visibleDataPoints: ChartDataPoint[] = [];
  public visibleDataPointsByUnit: DataPointsByUnit[] = [];

  private dimensions = {
    width: 1792,
    height: 488,
    margins: {
      top: 8,
      right: 0,
      bottom: 32,
      left: 0,
    },
    containerWidth: 0,
    containerHeight: 0,
    marginStep: 44,
  };
  private datePlot = DatePlot;
  private svg;
  private x; // common x-axis
  private yScaleEmpty;
  private tooltipDot;
  private yScaleEmptyMax = 16; // max value to correct grid lines

  constructor(
    private cdr: ChangeDetectorRef,
    private tooltipForPlotService: TooltipForPlotService
  ) {
    super();
  }

  ngAfterViewInit() {
    this.dimensions.width *= this.scaleFactor;
    this.dimensions.height *= this.scaleFactor;
    this.prepareCommonData();
    this.redrawSvg();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.tooltipForPlotService.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.svgContainer && changes.telemetry) {
      this.prepareCommonData();
      this.redrawSvg();
    }
  }

  public onMeasurementCheckboxClicked(event): void {
    this.telemetry.forEach(measurementTelemetry => {
      if (measurementTelemetry.lineId === event.id) {
        measurementTelemetry.isVisible = event.event.target.checked;
      }
    });

    this.prepareCommonData();
    this.redrawSvg();
  }

  private getSvg() {
    this.dimensions.containerWidth =
      this.dimensions.width -
      this.dimensions.margins.left -
      this.dimensions.margins.right;
    this.dimensions.containerHeight =
      this.dimensions.height -
      this.dimensions.margins.top -
      this.dimensions.margins.bottom;

    // create container
    const svgWrap = d3
      .create('svg')
      .attr('width', this.dimensions.width)
      .attr('height', this.dimensions.height)
      .attr(
        'viewBox',
        `0 0 ${this.dimensions.width} ${this.dimensions.height}`
      );

    // Selections
    this.svg = svgWrap
      .append('g')
      .attr(
        'transform',
        `translate(${this.dimensions.margins.left}, ${this.dimensions.margins.top})`
      );

    // Add X axis --> it is a date format
    this.x = d3
      .scaleTime()
      .domain(d3.extent(this.visibleDataPoints, d => d.time))
      .range([0, this.dimensions.containerWidth]);
    const xAxis = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(0, ${this.dimensions.containerHeight + 13})`
      )
      .call(
        d3
          .axisBottom(this.x)
          .ticks(this.datePlot.ticksNumber(this.dateTimeRange))
          .tickFormat(this.datePlot.formatXLabel(this.dateTimeRange))
      );
    xAxis.selectAll('path').remove();
    xAxis.selectAll('line').remove();

    // empty scaleY used for y grid lines
    this.yScaleEmpty = d3
      .scaleLinear()
      .domain([0, this.yScaleEmptyMax])
      .range([this.dimensions.containerHeight, 0]);

    this.tooltipForPlotService.create();

    // grid lines x
    const xGrid = g =>
      g
        .attr('class', 'grid-lines  grid-lines-x')
        .selectAll('line')
        .data(this.x.ticks(this.datePlot.ticksNumber(this.dateTimeRange)))
        .join('line')
        .attr('x1', d => this.x(d))
        .attr('x2', d => this.x(d))
        .attr('y1', 0)
        .attr('y2', this.dimensions.containerHeight + 15)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', 4)
        .attr('stroke', 'rgba(0, 0, 0, 0.08)');
    this.svg.append('g').call(xGrid);

    // grid lines y
    const yGrid = g =>
      g
        .attr('class', 'grid-lines grid-lines-y')
        .selectAll('line')
        .data(this.yScaleEmpty.ticks())
        .join('line')
        .attr('x1', 0)
        .attr('x2', this.dimensions.containerWidth)
        .attr('y1', d => this.yScaleEmpty(d))
        .attr('y2', d => this.yScaleEmpty(d))
        .attr('stroke-width', 1)
        .attr('stroke', 'rgba(0, 0, 0, 0.08)');
    this.svg.append('g').call(yGrid);

    // drawMultipleYAxis
    this.drawMultipleYAxis(svgWrap);

    //  tooltipDot
    this.tooltipDot = this.svg
      .append('circle')
      .attr('r', 4)
      .attr('fill', '#fc8781')
      .attr('stroke', '#fff')
      .attr('stroke-width', 2)
      .style('opacity', 0)
      .style('pointer-events', 'none');
    this.tooltipDot.raise();

    return svgWrap.node();
  }

  private redrawSvg() {
    this.destroySvg();
    this.svgContainer.nativeElement.appendChild(this.getSvg());
  }

  private destroySvg(): void {
    d3.select(this.svgContainer.nativeElement).selectAll('*').remove();
    this.tooltipForPlotService.hide();
  }

  private prepareCommonData() {
    this.checkboxOptions = this.prepareCheckBoxOptions(this.telemetry);
    this.visibleDataPoints = this.prepareFullData(this.telemetry);
    this.prepareDataPointsByUnit();
    this.dimensions.margins.left =
      this.getYAxisCountOnLeft() * this.dimensions.marginStep;
    this.dimensions.margins.right =
      this.getYAxisCountOnRight() * this.dimensions.marginStep;
  }

  private prepareCheckBoxOptions(
    data: TimeSeriesDataViewModel[]
  ): CheckBoxOptions[] {
    const timeSeriesArray = data;
    const resultCheckboxOptions: CheckBoxOptions[] = [];
    timeSeriesArray.forEach(el => {
      resultCheckboxOptions.push({
        id: el.lineId,
        name: el.name,
        currentValue: el.currentValue,
        unit: el.unit,
        color: '#' + el.lineColour,
        shown: el.isVisible,
      });
    });

    return resultCheckboxOptions;
  }

  private prepareFullData(data: TimeSeriesDataViewModel[]): ChartDataPoint[] {
    const timeSeriesDataArray = data;
    const result: ChartDataPoint[] = [];
    timeSeriesDataArray.forEach(measurementTelemetry => {
      if (measurementTelemetry.isVisible) {
        measurementTelemetry.values.forEach((value, index) => {
          result.push({
            name: measurementTelemetry.name,
            time: this.datePlot.parseTime(
              measurementTelemetry.timestamps[index]
            ),
            value: value,
            unit: measurementTelemetry.unit,
            color: '#' + measurementTelemetry.lineColour,
            shown: measurementTelemetry.isVisible,
            ...((measurementTelemetry.detailedNames ||
              measurementTelemetry.detailedNames?.length > 0) && {
              detailedNames: measurementTelemetry.detailedNames,
              detailedValues: this.getDetailValues(
                measurementTelemetry.detailedValues,
                index
              ),
            }),
          });
        });
      }
    });
    return result;
  }

  private prepareDataPointsByUnit() {
    const map = new Map<string, ChartDataPoint[]>();

    this.visibleDataPoints.forEach(dataPoint => {
      if (!map.has(dataPoint.unit)) {
        map.set(dataPoint.unit, []);
      }
      map.get(dataPoint.unit)?.push(dataPoint);
    });

    this.visibleDataPointsByUnit = [];
    map.forEach((points, unit) => {
      const item = new DataPointsByUnit();
      item.unit = unit;
      item.points = points;
      this.visibleDataPointsByUnit.push(item);
    });
  }

  private getDetailValues(arrayToConvert: any[], index: number): number[] {
    const arrayValues: number[] = [];
    arrayToConvert.forEach(el => arrayValues.push(el[index]));
    return arrayValues;
  }

  private drawMultipleYAxis(svgWrap): void {
    // divide into measure group
    this.visibleDataPointsByUnit
      .filter(data => data.unit !== '') // exclude heat requests
      .forEach((el, index) => {
        const minMaxValue = this.getMinMaxValue(el.points);
        const yScale = d3
          .scaleLinear()
          .domain([minMaxValue.valueMin, minMaxValue.valueMax])
          .range([this.dimensions.containerHeight, 0]);
        const yAxis = svgWrap
          .append('g')
          .attr(
            'transform',
            `translate(${this.positionXAxisLabel(index)}, ${this.dimensions.margins.top})`
          )
          .call(
            index % 2 == 1
              ? d3
                  .axisRight(yScale)
                  .tickValues(
                    this.gradationArray(
                      minMaxValue.valueMin,
                      minMaxValue.valueMax
                    )
                  )
                  .tickFormat(d3.format('d'))
              : d3
                  .axisLeft(yScale)
                  .tickValues(
                    this.gradationArray(
                      minMaxValue.valueMin,
                      minMaxValue.valueMax
                    )
                  )
                  .tickFormat(d3.format('d'))
          );
        yAxis.selectAll('path').remove();
        yAxis.selectAll('line').remove();

        // name axis
        yAxis
          .append('g')
          .attr('class', 'tick')
          .append('text')
          .attr('x', index % 2 == 1 ? 9 : -9)
          .attr('y', 20)
          .attr('fill', 'currentColor')
          .text(el.unit);

        // Add line for each type
        this.drawDataLines(el.points, yScale);

        // Add dots for tooltip (for each type)
        this.drawDataDots(el.points, yScale);
      });

    // lines/dots with no axis (such as heat requests)
    this.visibleDataPointsByUnit
      .filter(data => data.unit === '')
      .forEach(el => {
        // Add line
        this.drawDataLines(el.points, this.yScaleEmpty, true);
        // Add dots for tooltip
        this.drawDataDots(el.points, this.yScaleEmpty, true);
      });
  }

  private drawDataLines(
    points: ChartDataPoint[],
    yScale,
    isHeatRequestLine?: boolean
  ): void {
    const coefficient = isHeatRequestLine ? this.yScaleEmptyMax : 1;
    const sumstat = nest() // nest function allows to group the calculation per level of a factor
      .key(d => d.name)
      .entries(points);
    this.svg
      .selectAll('.line')
      .data(sumstat)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', d => d.values[0].color)
      .attr('stroke-width', 2)
      .attr('d', d => {
        return d3
          .line()
          .x((e: any) => this.x(e.time))
          .y((e: any) => yScale(+e.value * coefficient))
          .defined((e: any) => e.value || e.value === 0)(d.values);
      });
  }

  private drawDataDots(
    points: ChartDataPoint[],
    yScale,
    isHeatRequestLine?: boolean
  ) {
    const coefficient = isHeatRequestLine ? this.yScaleEmptyMax : 1;
    const circles = this.svg
      .append('g')
      .selectAll('dot')
      .data(points)
      .enter()
      .append('circle')
      .attr('cx', d => this.x(d.time))
      .attr('cy', d => yScale(+d.value * coefficient))
      .attr('r', 1)
      .attr('stroke', 'transparent')
      .attr('stroke-width', 7)
      .attr('fill', d => d.color)
      .style('cursor', 'pointer')
      .on('mouseover', (event, d) => {
        const cordX = event.target.cx.baseVal.value;
        const cordY = event.target.cy.baseVal.value;
        const dotColor = event.target.attributes.fill.value;
        this.tooltipDot
          .style('opacity', 1)
          .attr('cx', cordX)
          .attr('cy', cordY)
          .attr('fill', dotColor);
        this.tooltipForPlotService.show(d, this.dateTimeRange, event);
      })
      .on('mouseleave', () => {
        this.tooltipDot.style('opacity', 0);
        this.tooltipForPlotService.hide();
      });
    circles.filter(d => d.value === null).remove();
  }

  private getYAxisCountOnLeft(): number {
    return Math.max(
      Math.ceil(this.visibleDataPointsByUnit.filter(x => !!x.unit).length / 2),
      this.keepSpaceForYAxis ?? 0
    );
  }
  private getYAxisCountOnRight(): number {
    return Math.max(
      Math.floor(this.visibleDataPointsByUnit.filter(x => !!x.unit).length / 2),
      this.keepSpaceForYAxis ?? 0
    );
  }

  private positionXAxisLabel(index: number): number {
    const isLeft = index % 2 == 0;
    const marginOffset =
      (isLeft ? this.getYAxisCountOnLeft() : this.getYAxisCountOnRight()) -
      Math.floor(index / 2);

    return isLeft
      ? (this.dimensions.margins.left / this.getYAxisCountOnLeft()) *
          marginOffset
      : this.dimensions.width -
          (this.dimensions.margins.right / this.getYAxisCountOnRight()) *
            marginOffset;
  }

  private getMinMaxValue(data: any): { valueMax: number; valueMin: number } {
    let valueMax = Math.ceil(d3.max(data, (d: any) => +d.value));
    let valueMin = Math.floor(
      d3.min(
        data.filter(el => el.value !== null),
        (d: any) => +d.value
      )
    );
    if (valueMax === 0) {
      valueMax = 4;
    }
    const diffRem = (valueMax - valueMin) % 4;
    if (diffRem === 3) {
      valueMax++;
    } else if (diffRem === 2) {
      if (valueMin === 0) {
        valueMax += 2;
      } else {
        valueMax++;
        valueMin--;
      }
    } else if (diffRem === 1) {
      if (valueMin === 0) {
        valueMax += 3;
      } else {
        valueMax += 2;
        valueMin--;
      }
    }
    return { valueMax, valueMin };
  }

  private gradationArray(minValue: number, maxValue: number): number[] {
    const diff = maxValue - minValue;
    return [
      minValue,
      minValue + diff / 4,
      minValue + diff / 2,
      minValue + (diff / 4) * 3,
      maxValue,
    ];
  }
}
