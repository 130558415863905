import { AlertStatus } from './alert-status';

export class Alert {
  id: string;
  seen: boolean;
  componentType: number;
  componentName: string;
  severity: number;
  message: string;
  date: string;
  time: string;
  fullTime: string;
  status: AlertStatus;
}
