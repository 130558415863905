import { HttpParams } from '@angular/common/http';

export class ObjectToHttpParams {
  static convert(data: any): HttpParams {
    let params = new HttpParams();
    Object.keys(data).forEach(item => {
      if (data[item]) {
        params = params.set(item, data[item]);
      }
    });
    return params;
  }
}
