import { PeriodTab } from '../constants/enum';
import { DateTimeRange } from '../classes/data-charts';
import * as d3 from 'd3';

export class DatePlot {
  static parseTime(date: string) {
    return new Date(date);
  }

  static getDateTimeRange(period: PeriodTab): DateTimeRange {
    const start = new Date();
    const end = new Date();
    switch (period) {
      case PeriodTab.lastHour:
        start.setHours(start.getHours() - 1);
        break;
      case PeriodTab.today:
        start.setHours(0, 0, 0, 0);
        break;
      case PeriodTab.yesterday:
        start.setHours(start.getHours() - 24);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        break;
      case PeriodTab.last3Days:
        start.setHours(start.getHours() - 72);
        break;
      case PeriodTab.week:
        start.setHours(start.getHours() - 168);
        break;
      case PeriodTab.month:
        start.setMonth(start.getMonth() - 1);
        break;
      case PeriodTab.year:
        start.setMonth(start.getMonth() - 12);
        break;
      case PeriodTab.entireTime:
        return { startDate: null, endDate: null };
    }

    const result = new DateTimeRange();
    result.startDate = start.toISOString();
    result.endDate = end.toISOString();
    return result;
  }

  static updateTimePeriod(
    timePeriodName: PeriodTab,
    timePeriod: DateTimeRange,
    today: Date
  ): DateTimeRange {
    const endDate = new Date(timePeriod.endDate);
    if (timePeriodName !== PeriodTab.custom) {
      return DatePlot.getDateTimeRange(timePeriodName);
    } else if (
      timePeriodName === PeriodTab.custom &&
      endDate.toDateString() === today.toDateString()
    ) {
      const timeNow = new Date();
      timePeriod.endDate = timeNow.toISOString();
      return timePeriod;
    } else {
      return timePeriod;
    }
  }

  static formatXLabel(periodTime: DateTimeRange): any {
    const startDay = new Date(periodTime.startDate).getTime();
    const endDay = new Date(periodTime.endDate).getTime();
    const diff = endDay - startDay;
    const diffInDays = diff / 1000 / 60 / 60 / 24;
    if (diffInDays > 4) {
      return d3.timeFormat('%d-%m-%Y');
    } else if (diffInDays > 1) {
      return d3.timeFormat('%d-%m, %H:%M');
    } else {
      return d3.timeFormat('%H:%M');
    }
  }

  static ticksNumber(periodTime: DateTimeRange): any {
    const startDay = new Date(periodTime.startDate).getTime();
    const endDay = new Date(periodTime.endDate).getTime();
    const diff = endDay - startDay;
    const diffInDays = diff / 1000 / 60 / 60 / 24;
    return diffInDays > 60 && diffInDays < 90 ? 4 : 6;
  }

  static formatTooltipTimeLabel(periodTime: DateTimeRange): any {
    const startDay = new Date(periodTime.startDate).getTime();
    const endDay = new Date(periodTime.endDate).getTime();
    const diff = endDay - startDay;
    const diffInDays = diff / 1000 / 60 / 60 / 24;
    if (diffInDays > 1) {
      return d3.timeFormat('%H:%M (%d-%m-%Y)');
    } else {
      return d3.timeFormat('%H:%M');
    }
  }
}
