import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../../../models/alert';
import { ViewCell } from 'ng2-smart-table';
import { BaseComponent } from '../../../../common/classes/base-component';
import { UserService } from '../../../../common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertsTableService } from '../../../services/alerts-table.service';
import { Constants } from '../../../../common/constants/constants';

@Component({
  selector: 'app-seen-unseen',
  templateUrl: './seen-unseen.component.html',
  styleUrls: ['./seen-unseen.component.scss'],
})
export class SeenUnseenComponent
  extends BaseComponent
  implements ViewCell, OnInit
{
  @Input() value: any;
  @Input() rowData: Alert;

  public seenState: number | string;
  public seenStateTitle: number | string;
  public seenStateIcon: any;

  public energyCenterId: string;

  constructor(
    private alertsTableService: AlertsTableService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    super();
    this.userService.currentEnergyCenter$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(energyCenterContext => !!energyCenterContext))
      .subscribe(energyCenterContext => {
        this.energyCenterId = energyCenterContext.id;
      });
  }

  ngOnInit(): void {
    this.seenState = this.rowData.seen ? 'seen' : 'unseen';
    this.seenStateTitle = this.translateService.instant(
      'alerts.table.' + this.seenState
    );
    this.seenStateIcon =
      Constants.ICON_URL + (this.rowData.seen ? 'seen' : 'unseen') + '.svg';
  }

  onClick(): void {
    if (!this.rowData.seen) {
      this.alertsTableService.markAlertAsSeen(this.rowData);
    } else {
      this.alertsTableService.markAlertAsUnseen(this.rowData);
    }
  }
}
