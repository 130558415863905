/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AlertDto {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  timestamp?: string;
  severity?: AlertSeverity;
  /** Component info */
  component?: ComponentDto | null;
  message?: string | null;
  seen?: boolean;
  resolved?: boolean;
}

export interface AlertDtoPagedResponse {
  items?: AlertDto[] | null;
  /** @minLength 1 */
  nextPageToken: string;
}

/** @format int32 */
export enum AlertScope {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum AlertSeverity {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface AlertsIdDto {
  alertIds: string[] | null;
}

/** Boiler component data to be shown on the Heat Grid diagram */
export interface Boiler {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
  /** Component status */
  componentStatus?: ComponentStatusDto | null;
  /** Target power */
  targetPower?: MeasurementValueDto | null;
  /** Current power */
  currentPower?: MeasurementValueDto | null;
}

/** Boiler shape layout schema and corresponding component data */
export interface BoilerShape {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  position?: Point | null;
  /** Boiler component data to be shown on the Heat Grid diagram */
  data?: Boiler | null;
}

/** CHP component data to be shown on the Heat Grid diagram */
export interface CHP {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
  /** Component status */
  componentStatus?: ComponentStatusDto | null;
  /** Target power */
  targetPower?: MeasurementValueDto | null;
  /** Current power */
  currentPower?: MeasurementValueDto | null;
}

/** CHP shape layout schema and corresponding component data */
export interface CHPShape {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  position?: Point | null;
  /** CHP component data to be shown on the Heat Grid diagram */
  data?: CHP | null;
}

/** Represents a data transfer object (DTO) for a community district. */
export interface CommunityDistrictDto {
  /** Community district id */
  id?: string | null;
  /** Community district name */
  name?: string | null;
}

export interface CommunityDistrictsSummaryDto {
  heatingNetworks?: HeatingNetworkDto[] | null;
}

export interface CommunityDto {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  state: string;
  /** @minLength 1 */
  street: string;
  energyOperator: EnergyOperatorDto;
}

export interface CommunityRootComponentsDto {
  communityName: string | null;
  energyCenters?: ComponentWithDeviceCountDto[] | null;
  houses?: ComponentWithDeviceCountDto[] | null;
}

/** Selected community */
export interface CommunityUserContextDto {
  /**
   * Community id
   * @minLength 1
   */
  id: string;
  /**
   * Community name
   * @minLength 1
   */
  name: string;
  /** Energy Operator where the given community is located */
  energyOperator: EnergyOperatorUserContextDto;
}

/** Component info */
export interface ComponentDto {
  /** Component id */
  id: string | null;
  /**
   * Component name
   * @minLength 1
   */
  name: string;
  /** Component type */
  type: ComponentTypeDto;
  /** Component status */
  status?: ComponentStatusDto | null;
}

/** Component info */
export interface ComponentExtDto {
  /** Component id */
  id: string | null;
  /**
   * Component name
   * @minLength 1
   */
  name: string;
  /** Component type */
  type: ComponentTypeDto;
  /** Component status */
  status?: ComponentStatusDto | null;
  /** Path to the parent component */
  parentComponentsPath?: ComponentDto[] | null;
  /** Component location info */
  location: ComponentLocationInfoDto | null;
}

/** Basic component info */
export interface ComponentInfoDto {
  /** Component id */
  id: string | null;
  /**
   * Component name
   * @minLength 1
   */
  name: string;
  /** Component type */
  type: ComponentTypeDto;
}

export interface ComponentLinkRequestDto {
  componentId: string | null;
}

/** Component location info */
export interface ComponentLocationInfoDto {
  /** Name */
  name?: string | null;
  /** Postal code */
  postcode?: string | null;
  /** Street name */
  street?: string | null;
  /** House number */
  houseNumber?: string | null;
  /** Community name */
  communityName?: string | null;
}

export interface ComponentStatsVisibilityDto {
  /** Component id */
  id: string | null;
  /**
   * Component name
   * @minLength 1
   */
  name: string;
  /** Component type */
  type: ComponentTypeDto;
  /** Component status */
  status?: ComponentStatusDto | null;
  /** Whether it should be displayed on the page */
  visible?: boolean;
}

/** Component status */
export interface ComponentStatusDto {
  /** Component status id */
  id: ComponentStatusId;
  /**
   * Component status name
   * @minLength 1
   */
  name: string;
}

/** @format int32 */
export enum ComponentStatusId {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface ComponentTelemetryDataDto {
  /** Component info */
  component?: ComponentDto | null;
  telemetry?: TimeSeriesData[] | null;
}

export interface ComponentTreeDto {
  /** Component location info */
  location: ComponentLocationInfoDto | null;
  components?: ComponentTreeNodeDto[] | null;
}

export interface ComponentTreeNodeDto {
  /** Component info */
  component: ComponentDto | null;
  canLink?: boolean;
  /** @format int32 */
  devicesCount?: number | null;
  linkedDevices?: LinkedDeviceDto[] | null;
  measurementTypes?: MeasurementTypeExtDto[] | null;
  childNodes?: ComponentTreeNodeDto[] | null;
}

/** Component type model */
export interface ComponentTypeDto {
  /** Component type id */
  id?: ComponentTypeId;
  /**
   * Component type name
   * @minLength 1
   */
  name: string;
}

/** @format int32 */
export enum ComponentTypeId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value30 = 30,
  Value31 = 31,
  Value32 = 32,
  Value33 = 33,
  Value40 = 40,
  Value50 = 50,
  Value51 = 51,
  Value60 = 60,
  Value70 = 70,
  Value71 = 71,
}

export interface ComponentTypeSummary {
  /** Component type model */
  componentType: ComponentTypeDto;
  components: ComponentDto[];
  heatingProduction: OverviewMeasurementWithLimitsDto;
  heatTemperature: HeatTemperatureDto;
  electricityProduction: OverviewMeasurementWithLimitsDto;
  electricityConsumption: OverviewMeasurementDto;
  gasConsumption: OverviewMeasurementDto;
}

export interface ComponentVisibilitySettingsDto {
  /** Component id */
  id?: string | null;
  /** Whether it should be displayed on the page */
  visible?: boolean;
}

export interface ComponentWithDeviceCountDto {
  rootComponentId: string | null;
  locationName: string | null;
  /** @format int32 */
  devicesCount?: number;
}

export interface ComponentsStatisticsSettingsDto {
  components?: ComponentVisibilitySettingsDto[] | null;
}

/** @format int32 */
export enum DataTypeId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface DeviceComponentLinkDto {
  /** Component info */
  component: ComponentExtDto | null;
  measurementTypes?: MeasurementTypeExtDto[] | null;
  linkStatus: DeviceComponentLinkStatusDto | null;
}

export interface DeviceComponentLinkStatusDto {
  id?: DeviceComponentStatusId;
  name: string | null;
}

/** @format int32 */
export enum DeviceComponentStatusId {
  Value0 = 0,
  Value1 = 1,
}

export interface DeviceConnectionModeDto {
  id: DeviceConnectionModeId;
  /** @minLength 1 */
  name: string;
}

/** @format int32 */
export enum DeviceConnectionModeId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface DeviceConnectionStatusDto {
  id?: DeviceConnectionStatusId;
  name: string | null;
}

/** @format int32 */
export enum DeviceConnectionStatusId {
  Value0 = 0,
  Value1 = 1,
  Value11 = -1,
}

export interface DeviceCreateDto {
  name?: string | null;
  /** @format int32 */
  deviceModelVersionId?: number | null;
  integrationId?: string | null;
  notes?: string | null;
  gatewayDeviceId?: string | null;
  protocolAttributes?: DeviceProtocolAttributesDto | null;
  deviceTypeId?: DeviceTypeId;
  connectionModeId?: DeviceConnectionModeId;
}

export interface DeviceDeleteDto {
  removeTelemetryData?: boolean;
}

export interface DeviceDetailsDto {
  /** @minLength 1 */
  id: string;
  integrationId?: string | null;
  name?: string | null;
  deviceType: DeviceTypeDto;
  connectionMode: DeviceConnectionModeDto;
  /** @format date-time */
  lastActiveOnUtc?: string | null;
  status?: DeviceStatusDto | null;
  deviceModelVersion?: DeviceModelVersionDto | null;
  linkedComponent?: DeviceComponentLinkDto | null;
  iotHubDeviceId?: string | null;
  iotHubDeviceConnectionString?: string | null;
  networkServerDeviceId?: string | null;
  gatewayDeviceId?: string | null;
  notes?: string | null;
  protocolAttributes?: DeviceProtocolAttributesDto | null;
  connectedDevices?: DeviceDto[] | null;
  hasUnseenAlerts?: boolean;
  edgeModulesDetails?: DeviceEdgeModulesDetailsDto | null;
}

export interface DeviceDto {
  /** @minLength 1 */
  id: string;
  integrationId?: string | null;
  name?: string | null;
  deviceType: DeviceTypeDto;
  connectionMode: DeviceConnectionModeDto;
  /** @format date-time */
  lastActiveOnUtc?: string | null;
  status?: DeviceStatusDto | null;
  deviceModelVersion?: DeviceModelVersionDto | null;
  linkedComponent?: DeviceComponentLinkDto | null;
}

export interface DeviceDtoPagedResponse {
  items?: DeviceDto[] | null;
  /** @minLength 1 */
  nextPageToken: string;
}

export interface DeviceEdgeModuleDetailsDto {
  id: string | null;
  connectionStatus: DeviceConnectionStatusDto | null;
}

export interface DeviceEdgeModulesDetailsDto {
  healthCheckModuleSuccess?: boolean | null;
  modules?: DeviceEdgeModuleDetailsDto[] | null;
}

export interface DeviceEmulationDataDto {
  measurements: DeviceMeasurementDto[] | null;
}

export interface DeviceIdDto {
  id: string | null;
}

export interface DeviceLorawanAttributesDto {
  devEui: string | null;
  joinEui: string | null;
  lorawanVersion: string | null;
  regionalParametersVersion: string | null;
  frequencyPlanId: string | null;
  appKey: string | null;
  nwkKey?: string | null;
}

export interface DeviceMeasurementDto {
  measurementTypeId?: MeasurementTypeId;
  /** @format double */
  value?: number;
}

export interface DeviceMeasurementTypeDto {
  id: MeasurementTypeId;
  /** @minLength 1 */
  name: string;
  dataType?: DataTypeId;
  unit?: string | null;
  isValid?: boolean;
  possibleIntegerValues?: IntegerValue[] | null;
}

export interface DeviceMeasurementTypesDataDto {
  measurementTypes?: DeviceMeasurementTypeDto[] | null;
}

export interface DeviceModelVersionDto {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  version: string;
  /** @minLength 1 */
  manufacturer: string;
}

export interface DeviceProtocolAttributesDto {
  lorawanAttributes?: DeviceLorawanAttributesDto | null;
}

export interface DeviceStatusDto {
  id: DeviceStatusId;
  /** @minLength 1 */
  name: string;
}

/** @format int32 */
export enum DeviceStatusId {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface DeviceTelemetryDataDto {
  /** @format date-span */
  pointsFrequency?: string | null;
  telemetry?: TimeSeriesData[] | null;
}

export interface DeviceTypeDto {
  id: DeviceTypeId;
  /** @minLength 1 */
  name: string;
}

/** @format int32 */
export enum DeviceTypeId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface DeviceTypeMetadataDto {
  deviceTypes: Int32StringKeyValuePair[] | null;
  deviceConnectionModes: Int32StringKeyValuePair[] | null;
  deviceModelVersions: DeviceModelVersionDto[] | null;
  lorawan: LorawanAttributesMetadata | null;
}

export interface DeviceUpdateDto {
  name?: string | null;
  /** @format int32 */
  deviceModelVersionId?: number | null;
  integrationId?: string | null;
  notes?: string | null;
  gatewayDeviceId?: string | null;
  protocolAttributes?: DeviceProtocolAttributesDto | null;
}

export interface DiagramElement {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
}

export interface DiagramElementRenderConfig {
  settings?: Record<string, string>;
}

/** Energy Center that given User has access to */
export interface EnergyCenterAvailabilityContextDto {
  /** Energy Center info */
  energyCenter: EnergyCenterDto;
  /** True if the Energy Center is a user's default, otherwise False */
  default: boolean;
}

export interface EnergyCenterAvailabilityContextDtoPagedResponse {
  items?: EnergyCenterAvailabilityContextDto[] | null;
  /** @minLength 1 */
  nextPageToken: string;
}

/** Energy Center details */
export interface EnergyCenterDto {
  /**
   * Energy Center id
   * @minLength 1
   */
  id: string;
  /**
   * Energy Center name
   * @minLength 1
   */
  name: string;
  /** Community that Energy Center belongs to */
  community: CommunityDto;
  /**
   * Energy Center postcode
   * @minLength 1
   */
  postcode: string;
  /**
   * Energy Center city
   * @minLength 1
   */
  city: string;
  /**
   * Energy Center state
   * @minLength 1
   */
  state: string;
  /**
   * Energy Center street
   * @minLength 1
   */
  street: string;
  /**
   * Energy Center house number
   * @minLength 1
   */
  houseNumber: string;
}

/** Selected energy center */
export interface EnergyCenterUserContextDto {
  /**
   * Energy center id
   * @minLength 1
   */
  id: string;
  /**
   * Energy center name
   * @minLength 1
   */
  name: string;
  /** Community where the given energy center is located */
  community: CommunityUserContextDto;
  /** Whether a current user has access to multiple energy centers */
  hasAccessToSeveralEnergyCenters: boolean;
}

export interface EnergyOperatorDto {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
}

export interface EnergyOperatorRootComponentsDto {
  communities?: CommunityRootComponentsDto[] | null;
}

/** Selected Energy Operator */
export interface EnergyOperatorUserContextDto {
  /**
   * Community id
   * @minLength 1
   */
  id: string;
  /**
   * Energy Operator ame
   * @minLength 1
   */
  name: string;
}

/** Heat grid data to be rendered on diagram */
export interface HeatGridDataDto {
  /** Collection of the Heat grid diagram elements */
  cells?:
    | (
        | Link
        | Shape
        | BoilerShape
        | CHPShape
        | HeatNetworkShape
        | HeatPipeLink
        | HeatStorageShape
        | HydraulicSwitchShape
      )[]
    | null;
}

/** Heat network component data to be shown on the Heat Grid diagram */
export interface HeatNetwork {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
}

/** Heat network shape layout schema and corresponding component data */
export interface HeatNetworkShape {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  position?: Point | null;
  /** Heat network component data to be shown on the Heat Grid diagram */
  data?: HeatNetwork | null;
}

export interface HeatNetworkTemperatureTelemetry {
  directFlowTelemetry: TimeSeriesData;
  returnFlowTelemetry: TimeSeriesData;
}

/** Heat pipe component data to be shown on the Heat Grid diagram */
export interface HeatPipe {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
  /** Temperature */
  temperature?: MeasurementValueDto | null;
  /** Liquid flow rate */
  liquidFlowRate?: MeasurementValueDto | null;
  /** Other additional measurements */
  measurements: MeasurementDto[];
}

/** Heat pipe link layout schema and corresponding component data */
export interface HeatPipeLink {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  source?: LinkConnectionPoint | null;
  target?: LinkConnectionPoint | null;
  vertices?: Point[] | null;
  /** Heat pipe component data to be shown on the Heat Grid diagram */
  data?: HeatPipe | null;
}

/** Heat storage component data to be shown on the Heat Grid diagram */
export interface HeatStorage {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
  /** Temperature measurements of the Heat storage parts */
  temperatures?: MeasurementValueDto[] | null;
}

/** Heat storage shape layout schema and corresponding component data */
export interface HeatStorageShape {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  position?: Point | null;
  /** Heat storage component data to be shown on the Heat Grid diagram */
  data?: HeatStorage | null;
}

export interface HeatStorageSummaryDto {
  /** Component info */
  component: ComponentDto;
  /** Current and target temperatures */
  heatTemperature: HeatTemperatureWithTrendDto;
  /** Accumulated power, kWh */
  powerCapacity: MeasurementValueWithPercentDto;
  /**
   * Volume in liters
   * @format int32
   */
  volume?: number;
}

export interface HeatTemperatureDto {
  /** @format double */
  directFlowValue?: number | null;
  /** @format double */
  returnFlowValue?: number | null;
  /** @format double */
  target?: number | null;
  unit: string | null;
}

export interface HeatTemperatureWithTrendDto {
  /** @format double */
  directFlowValue?: number | null;
  /** @format double */
  returnFlowValue?: number | null;
  /** @format double */
  target?: number | null;
  unit: string | null;
  increasing?: boolean | null;
}

export interface HeatingNetworkDto {
  /** Component info */
  component: ComponentDto;
  heatTemperature: HeatTemperatureDto;
  heatingConsumption: OverviewMeasurementDto;
}

export interface HouseCreateDto {
  name?: string | null;
  communityDistrictId?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  /** @format int32 */
  flatNumber?: number | null;
  postcode?: string | null;
  contractNumber?: string | null;
}

/** House details */
export interface HouseDetailsDto {
  /** House id */
  id?: string | null;
  /**
   * House address: street
   * @minLength 1
   */
  street: string;
  /**
   * House address: number
   * @minLength 1
   */
  houseNumber: string;
  /**
   * House address: flat number (if exists)
   * @format int32
   */
  flatNumber?: number | null;
}

/** House details */
export interface HouseDto {
  /**
   * House id
   * @minLength 1
   */
  id: string;
  /**
   * Id of the house as component container. It used in the telemetry request
   * @minLength 1
   */
  componentId: string;
  /** Represents a data transfer object (DTO) for a community district. */
  communityDistrict: CommunityDistrictDto;
  community: CommunityDto;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  street: string;
  /** @minLength 1 */
  postcode: string;
  /** @minLength 1 */
  houseNumber: string;
  flatNumber?: string | null;
  /** @minLength 1 */
  contractNumber: string;
  /**
   * Current heat consumption power value
   * @format double
   */
  heatConsumption?: number | null;
  /**
   * Current heat production power value
   * @format double
   */
  heatProduction?: number | null;
  /** @format double */
  waterFlowTemperature?: number | null;
  /** @format double */
  waterFlowTargetTemperature?: number | null;
  /** @format double */
  waterReturnFlowTemperature?: number | null;
  /** @format double */
  electricityConsumption?: number | null;
  /** @format double */
  electricityProduction?: number | null;
}

/** House id model */
export interface HouseIdDto {
  /** House id */
  id?: string | null;
}

export interface HouseStatsVisibilityDto {
  /** Component id */
  id: string | null;
  /**
   * Component name
   * @minLength 1
   */
  name: string;
  /** Component type */
  type: ComponentTypeDto;
  /** Component status */
  status?: ComponentStatusDto | null;
  /** Whether it should be displayed on the page */
  visible?: boolean;
  /** House details */
  house?: HouseDetailsDto | null;
}

export interface HouseUpdateDto {
  name?: string | null;
  communityDistrictId?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  /** @format int32 */
  flatNumber?: number | null;
  postcode?: string | null;
  contractNumber?: string | null;
}

/** Hydraulic switch component data to be shown on the Heat Grid diagram */
export interface HydraulicSwitch {
  /** Component information */
  componentInfo?: ComponentInfoDto | null;
}

/** Hydraulic switch shape layout schema and corresponding component data */
export interface HydraulicSwitchShape {
  id?: string | null;
  type?: string | null;
  renderConfig?: DiagramElementRenderConfig | null;
  position?: Point | null;
  /** Hydraulic switch component data to be shown on the Heat Grid diagram */
  data?: HydraulicSwitch | null;
}

export interface Int32StringKeyValuePair {
  /** @format int32 */
  key?: number;
  value?: string | null;
}

export interface IntegerValue {
  /** @format int32 */
  value: number;
  name: string | null;
}

export type Link = DiagramElement & {
  source?: LinkConnectionPoint | null;
  target?: LinkConnectionPoint | null;
  vertices?: Point[] | null;
};

export interface LinkConnectionPoint {
  id?: string | null;
  port?: string | null;
}

export interface LinkedDeviceDto {
  id: string | null;
  name?: string | null;
  deviceModelVersion?: DeviceModelVersionDto | null;
}

export interface LorawanAttributesMetadata {
  lorawanVersions: string[] | null;
  regionalParametersVersions: string[] | null;
  frequencyPlanIds: string[] | null;
}

export interface MeasurementDto {
  measurementType: MeasurementTypeDto;
  /** @minLength 1 */
  unit: string;
  /** @format double */
  value?: number | null;
}

export interface MeasurementTypeDto {
  id: MeasurementTypeId;
  /** @minLength 1 */
  name: string;
  dataType?: DataTypeId;
  unit?: string | null;
}

export interface MeasurementTypeExtDto {
  measurementType: MeasurementTypeDto;
  /** @minLength 1 */
  unit: string;
}

/** @format int32 */
export enum MeasurementTypeId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value30 = 30,
  Value31 = 31,
  Value60 = 60,
  Value61 = 61,
  Value90 = 90,
  Value91 = 91,
  Value120 = 120,
  Value121 = 121,
  Value122 = 122,
  Value51 = -5,
  Value42 = -4,
  Value33 = -3,
  Value24 = -2,
  Value15 = -1,
}

export interface MeasurementValueDto {
  /** @format int32 */
  roundingPrecision?: number;
  /**
   * Null in case there is no data yet for a particular measurement
   * @format double
   */
  value?: number | null;
  /** @minLength 1 */
  unit: string;
}

export interface MeasurementValueWithPercentDto {
  /** @format int32 */
  roundingPrecision?: number;
  /**
   * Null in case there is no data yet for a particular measurement
   * @format double
   */
  value?: number | null;
  /** @minLength 1 */
  unit: string;
  /** @format double */
  percentValue?: number;
}

/** Overview diagram data */
export interface OverviewDiagramDto {
  /** Public grid data */
  publicGridSummary: PublicGridSummaryDto;
  /** Boilers summary data */
  boilersSummary: ComponentTypeSummary;
  /** CHP (Combined Heat and electricity Production) summary data */
  chPsSummary: ComponentTypeSummary;
  /** Heat Pump (Combined Electricity Consumption and Heat Production) summary data */
  heatPumpsSummary: ComponentTypeSummary;
  /** Solar Station summary data */
  solarStationsSummary: ComponentTypeSummary;
  /** Wind Turbine summary data */
  windTurbinesSummary: ComponentTypeSummary;
  /** Storages summary data */
  storageSummary: StoragesSummaryDto;
  /** Community districts data */
  communityDistricts: CommunityDistrictsSummaryDto;
}

export interface OverviewMeasurementDto {
  current: MeasurementValueDto;
  totalForPeriod?: MeasurementValueDto | null;
}

export interface OverviewMeasurementWithLimitsDto {
  current: MeasurementValueDto;
  totalForPeriod?: MeasurementValueDto | null;
  /** @format int32 */
  maxValue: number;
}

/** Phone number details */
export interface PhoneNumberDto {
  /**
   * Phone number country code. 49 for Germany, 1 for the USA
   * @minLength 1
   */
  countryCode: string;
  /**
   * Phone number without leading country codes or additional zeroes
   * @minLength 1
   */
  number: string;
  /**
   * Combination of + sign, country code and phone number, that could be displayed to users
   * @minLength 1
   */
  displayedFullNumber: string;
}

export interface Point {
  /** @format int32 */
  x?: number;
  /** @format int32 */
  y?: number;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

/** User details */
export interface ProfileDto {
  /** Unique user identifier */
  id?: string | null;
  /**
   * User firstname (name)
   * @minLength 1
   */
  firstName: string;
  /**
   * User lastname (surname)
   * @minLength 1
   */
  lastName: string;
  /**
   * User email address
   * @minLength 1
   */
  email: string;
  /** User phone number */
  phoneNumber?: PhoneNumberDto | null;
  /** Energy operator (company) name */
  energyOperatorName?: string | null;
  /** Selected energy center */
  energyCenterContext?: EnergyCenterUserContextDto | null;
  /** True, if the User has an Admin role */
  isAdmin: boolean;
}

export interface PublicGridSummaryDto {
  /** Electricity drawn from the public grid (currently, and over time) */
  electricityDraw: OverviewMeasurementDto;
  /** Biogas drawn from the public grid (currently, and over time) */
  bioGasGridDraw: OverviewMeasurementDto;
  /** Natural gas drawn from the public grid (currently, and over time) */
  naturalGasGridDraw: OverviewMeasurementDto;
}

export type Shape = DiagramElement & {
  position?: Point | null;
};

export interface StoragesSummaryDto {
  heatStorages?: HeatStorageSummaryDto[] | null;
}

export interface SystemStateDataDto {
  /** @format int32 */
  bioGasPercent?: number | null;
  /** @format int32 */
  naturalGasPercent?: number | null;
  /** System state diagram: chart 1 - heat lines */
  heatingTelemetry: TimeSeriesExtendedData[];
  /** System state diagram: chart 1 - Electricity lines */
  electricityTelemetry: TimeSeriesExtendedData[];
  /** System state diagram: chart 1 - Gas lines */
  gasTelemetry: TimeSeriesExtendedData[];
  /** System state diagram: chart 2 - temperature lines per each district */
  heatNetworksTemperatureTelemetry: HeatNetworkTemperatureTelemetry[];
  /** System state diagram: chart 2 - temperature lines per energy center component */
  temperatureTelemetry: TimeSeriesExtendedData[];
  /** System state diagram: chart 3 - Heating storage */
  heatingStorageTelemetry: TimeSeriesExtendedData[];
}

export interface TelemetryDataDto {
  /** @format date-span */
  pointsFrequency?: string | null;
  componentsData?: ComponentTelemetryDataDto[] | null;
}

/** Model to request telemetry data */
export interface TelemetryDataRequestDto {
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** Components ids. Note, that district network is also an energy center component */
  componentIds?: string[] | null;
}

export interface TimeSeriesData {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lineColour: string;
  /** @minLength 1 */
  lineId: string;
  /** @minLength 1 */
  unit: string;
  measurementTypeId: MeasurementTypeId;
  timestamps: string[];
  values: number[];
  /** @format double */
  currentValue?: number | null;
  secondaryName?: string | null;
}

export interface TimeSeriesExtendedData {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lineColour: string;
  /** @minLength 1 */
  lineId: string;
  /** @minLength 1 */
  unit: string;
  measurementTypeId: MeasurementTypeId;
  timestamps: string[];
  values: number[];
  /** @format double */
  currentValue?: number | null;
  secondaryName?: string | null;
  detailedNames?: string[] | null;
  detailedValues: number[][] | null;
}

/** Details to change a user's name */
export interface UpdateNameDto {
  /** Current password */
  firstName: string | null;
  /** New password */
  lastName: string | null;
}

/** Details to change a user's password */
export interface UpdatePasswordDto {
  /** Current password */
  currentPassword: string | null;
  /** New password */
  newPassword: string | null;
}

export interface ValidationProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  [key: string]: any;
}
