import { Component, Input, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormHelper } from '../../../common/helpers/form';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() attachedControl: AbstractControl;
  @Input() label?: string;
  @Input() isRequired = false;
  @Input() class?: string;
  @Input() errorInfoTemplate?: TemplateRef<any>;
  @Input() errorInfoTemplateContext?: any;

  protected readonly formHelper = FormHelper;
}
