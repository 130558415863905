<div class="widget-events-inner" [nbSpinner]="loading">
  <div class="widget-events-scroll scroll-custom">
    <div class="events-title" (click)="goToAlerts()">
      {{ 'alerts.header.title' | translate }}
      <span [inlineSVG]="'/assets/icons/arrow-right.svg'"></span>
    </div>
    <div class="events-list" *ngIf="alerts?.length > 0">
      <div class="event-single" *ngFor="let alert of alerts; let i = index">
        <div class="icon-with-text" #templateBody>
          <div
            class="icon status-icon {{ iconType.mapSeverity(alert.severity) }}"
            [inlineSVG]="iconType.iconFullPath(alert.componentType)"></div>
          <div class="info-text">
            {{ alert.message }}
            <div class="ago-info">{{ alert.fullTime | timeago }}</div>
          </div>
        </div>
        <div class="dismiss">
          <div
            (click)="removeFromList(alert, i)"
            [inlineSVG]="'/assets/icons/close.svg'"></div>
        </div>
        <div class="status">
          <div [inlineSVG]="iconType.statusIcon(alert.status)"></div>
        </div>
      </div>
    </div>
    <div *ngIf="alerts?.length === 0 && !loading" class="empty">
      {{ 'alerts.widget.empty-message' | translate }}
    </div>
  </div>
</div>
