import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NullDashPipe } from '../pipes/null-dash.pipe';

@Injectable({
  providedIn: 'root',
})
export class NumberService {
  public decimalPipe: DecimalPipe;
  public nullDashPipe: NullDashPipe;

  constructor(@Inject(LOCALE_ID) public locale: any) {
    this.nullDashPipe = new NullDashPipe();
    this.decimalPipe = new DecimalPipe(
      locale.localeService.translate.store.currentLang
    );
  }

  //  use in ts instead classic pipe
  // angular pipe number combined with '-' for null
  parse(value: number, digitInfo = '1.0-1') {
    const valuePipeNumber = this.decimalPipe.transform(value, digitInfo);
    return this.nullDashPipe.transform(valuePipeNumber);
  }
}
