import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PeriodDataNameInfo } from '../../common/classes/data-charts';

@Injectable({
  providedIn: 'root',
})
export class AlertsNavigateService {
  public currentTabAlert$: BehaviorSubject<PeriodDataNameInfo> =
    new BehaviorSubject(null);

  public setCurrentTabAlert(timeInfo: PeriodDataNameInfo): void {
    this.currentTabAlert$.next(timeInfo);
  }
}
