import { SeenUnseenComponent } from '../components/alerts-table-cells/seen-unseen/seen-unseen.component';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusComponent } from '../components/alerts-table-cells/status/status.component';
import { AlertCellComponent } from '../components/alerts-table-cells/alert-cell/alert-cell.component';

@Injectable({
  providedIn: 'any',
})
export class SettingsTableAlerts {
  public settings = {
    columns: {
      seen: {
        width: '100px',
        sort: false,
        type: 'custom',
        valuePrepareFunction: (value, row, cell) => {
          return cell;
        },
        renderComponent: SeenUnseenComponent,
      },
      message: {
        title: this.translateTh('alert'),
        sort: false,
        type: 'custom',
        valuePrepareFunction: (value, row, cell) => {
          return cell;
        },
        renderComponent: AlertCellComponent,
      },
      componentName: {
        title: this.translateTh('component'),
        sort: false,
      },
      date: {
        width: '150px',
        title: this.translateTh('date'),
        sort: false,
      },
      time: {
        width: '150px',
        title: this.translateTh('time'),
        sort: false,
      },
      status: {
        title: this.translateTh('status'),
        sort: false,
        type: 'custom',
        valuePrepareFunction: (value, row, cell) => {
          return cell;
        },
        renderComponent: StatusComponent,
      },
    },
    hideSubHeader: true,
    noDataMessage: this.translateService.instant('alerts.table.empty-message'),
    pager: {
      display: false,
      perPage: 25,
    },
    actions: null,
    rowClassFunction: row => {
      if (!row.data.seen) return 'unseen';
      else return '';
    },
  };

  constructor(private translateService: TranslateService) {}

  private translateTh(th: string): string {
    return this.translateService.instant('alerts.table.' + th);
  }
}
