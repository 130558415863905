import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class FormHelper {
  static errorField(form: FormGroup, field: string): boolean {
    return form.get(field).invalid && form.get(field).touched;
  }

  static onlyNumbers(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const onlyNumbersCheck =
        !nameRe.test(control.value) && control.value !== null;
      return onlyNumbersCheck
        ? { onlyNumbers: { value: control.value } }
        : null;
    };
  }

  static isEmpty(text: string): number {
    return text === '' || text === null ? null : Number(text);
  }

  static hasError(field: AbstractControl): boolean {
    return field.invalid && field.touched;
  }
}
