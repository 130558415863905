import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from './app-config-interface';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  protected appConfig: AppConfig | undefined;

  constructor(private http: HttpClient) {}

  public async loadAppConfig() {
    this.http
      .get<AppConfig>('/app-config/app-config.json')
      .subscribe(res => (this.appConfig = res));
  }

  public getConfig(): AppConfig {
    return this.appConfig;
  }

  public getConfigUrl(): string {
    return this.appConfig?.apiUrl;
  }

  public getTimeUpdateRequests(): number {
    const timeUpdate = this.appConfig.timeUpdateRequests
      ? this.appConfig.timeUpdateRequests
      : 30000;
    return timeUpdate;
  }
}
