import { AfterContentInit, Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-simple',
  templateUrl: './modal-simple.component.html',
  styleUrls: ['./modal-simple.component.scss'],
})
export class ModalSimpleComponent implements AfterContentInit {
  @Input() title: string;
  @Input() message?: string;
  @Input() okAction? = 'save';
  @Input() okText?: string;
  @Input() body?: string;
  @Input() showCancel = true;

  bodyTrusted: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    protected ref: NbDialogRef<ModalSimpleComponent>
  ) {}

  ngAfterContentInit() {
    if (this.body) {
      this.bodyTrusted = this.sanitizer.bypassSecurityTrustHtml(this.body);
    }
  }

  dismiss(save?: boolean): void {
    this.ref.close(save);
  }
}
