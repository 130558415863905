<div class="error-block" *ngIf="formElement.invalid">
  <span *ngIf="formElement.errors.required && formElement.touched">
    {{ 'errors.required' | translate }}
  </span>
  <span *ngIf="formElement.errors.maxlength">
    {{ 'errors.maximum' | translate }}
    {{ formElement.errors.maxlength.requiredLength }}
  </span>
  <span *ngIf="formElement.errors.onlyNumbers">
    {{ 'errors.only-numbers' | translate }}
  </span>
  <span *ngIf="formElement.errors.customError">
    {{ formElement.errors.customError | translate }}
  </span>
</div>
