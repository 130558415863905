import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../../../models/alert';
import { IconType } from '../../../../common/helpers/icon-type';

@Component({
  selector: 'app-alert-cell',
  templateUrl: './alert-cell.component.html',
  styleUrls: ['./alert-cell.component.scss'],
})
export class AlertCellComponent implements OnInit {
  @Input() value: any;
  @Input() rowData: Alert;

  public componentTypeIcon: any;
  public severity: string;
  public alertMessage: string;
  public iconType = IconType;

  ngOnInit(): void {
    this.componentTypeIcon = this.iconType.iconFullPath(
      this.rowData.componentType
    );
    this.severity = this.iconType.mapSeverity(this.rowData.severity);
    this.alertMessage = this.rowData.message;
  }
}
