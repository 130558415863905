import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private initialized = false;

  get currentLocale(): string {
    return this.translate.currentLang;
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    @Optional()
    @SkipSelf()
    otherInstance: LocaleService
  ) {
    if (otherInstance) {
      const err = new Error('LocaleService should have only one instance.');
      console.log(err);
    }
  }

  initLocale(localeId: string, defaultLocaleId = localeId) {
    if (this.initialized) return;

    this.setDefaultLocale(defaultLocaleId);
    this.setLocale(localeId);

    this.initialized = true;
  }

  setDefaultLocale(localeId: string) {
    this.translate.setDefaultLang(localeId);
  }

  setLocale(localeId: string) {
    this.translate.use(localeId);
  }
}
