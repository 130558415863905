<nb-card>
  <nb-card-header>
    <div class="period-title">
      <div class="uppercase">{{ 'common.period' | translate }}</div>
      <div class="range">{{ range.start | date }} - {{ range.end | date }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-calendar-range
      class="custom-calendar"
      [boundingMonth]="true"
      [max]="max"
      [(range)]="range"></nb-calendar-range>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="info" (click)="dismiss()">
      {{ 'common.cancel' | translate }}
    </button>
    <button
      nbButton
      status="info"
      [disabled]="!range.end"
      (click)="dismiss([range.start, range.end])">
      {{ 'common.apply' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
