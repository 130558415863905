import { Injectable } from '@angular/core';
import { DatePlot } from '../../common/helpers/datePlot';
import * as d3 from 'd3';
import { ChartDataPoint, DateTimeRange } from '../../common/classes/data-charts';
import { NumberService } from '../../common/services/number.service';
import { SpaceUnitPipe } from '../../common/pipes/space-unit.pipe';

@Injectable()
export class TooltipForPlotService {
  public tooltip: any;
  public datePlot = DatePlot;

  constructor(
    private numberService: NumberService,
    private spaceUnitPipe: SpaceUnitPipe
  ) {}

  public create(): void {
    const tooltipPlot = document.getElementsByClassName('tooltip-plot');
    if (tooltipPlot.length === 0) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip-plot');
    }
  }

  // default plotTooltip
  public show(d: ChartDataPoint, timePeriod: DateTimeRange, event): void {
    const formatTime = this.datePlot.formatTooltipTimeLabel(timePeriod);
    const time = formatTime(d.time);
    const value = this.numberService.parse(d.value);
    const unitMeasure = this.spaceUnitPipe.transform(d.unit);
    const detail = this.detailInfo(
      d.detailedNames,
      d.detailedValues,
      unitMeasure
    );
    const valueAndType = d.unit
      ? `${value}${unitMeasure}, ${time}`
      : `${time}`;
    const text = `<div class="tooltip-plot-inner"><div>${d.name}</div><b>${valueAndType}</b>${detail}</div>`;
    this.tooltip.html(text);
    const dimensions = this.tooltip.node().getBoundingClientRect();
    const isRight = this.isRightPosition(dimensions.width, event.clientX);
    this.tooltip
      .classed('bg-detail', !!detail)
      .style('left', this.leftPosition(dimensions.width, event.clientX) + 'px')
      .style('top', event.clientY - dimensions.height - 10 + 'px')
      .style('border-radius', isRight ? '16px 16px 16px 4px' : '16px 16px 4px')
      .style('visibility', 'visible')
      .style('z-index', '10000');
  }

  public hide(): void {
    this.tooltip?.style('visibility', 'hidden').style('z-index', '-10');
  }

  public remove(): void {
    const tooltipPlot = document.getElementsByClassName('tooltip-plot');
    tooltipPlot[0]?.remove();
  }

  private leftPosition(widthBlock: number, leftBlock: number): number {
    return this.isRightPosition(widthBlock, leftBlock)
      ? leftBlock
      : leftBlock - widthBlock;
  }

  private isRightPosition(widthBlock: number, leftBlock: number): boolean {
    const bodyWidth = document.getElementsByTagName('body')[0].scrollWidth;
    return bodyWidth - widthBlock - leftBlock > 0;
  }

  private detailInfo(
    names: string[],
    values: number[],
    typeMeasure: string
  ): string {
    if (!names || names?.length === 0) {
      return '';
    } else {
      let infoLines = '';
      names.forEach((el, index) => {
        const value = this.numberService.parse(values[index]);
        infoLines += `<div class='detail-line'><div>${el}</div><span>${value}${typeMeasure}</span></div>`;
      });
      return `<div class='detail-lines'>${infoLines}<div>`;
    }
  }
}
