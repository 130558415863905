import { Injectable } from '@angular/core';
import {
  EnergyCenterUserContextDto,
  ProfileDto,
} from '../models/data-contracts';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser$: BehaviorSubject<ProfileDto> =
    new BehaviorSubject<ProfileDto>(null);
  public currentEnergyCenter$: BehaviorSubject<EnergyCenterUserContextDto> =
    new BehaviorSubject<EnergyCenterUserContextDto>(null);

  public setCurrentUser$(user: ProfileDto): void {
    this.currentUser$.next(user);
    if (this.currentEnergyCenter$.value) {
      this.setCurrentEnergyCenter$(user.energyCenterContext);
    }
  }

  public setCurrentEnergyCenter$(value: EnergyCenterUserContextDto) {
    this.currentEnergyCenter$.next({
      ...value,
      hasAccessToSeveralEnergyCenters:
        this.currentUser$.value.energyCenterContext
          .hasAccessToSeveralEnergyCenters,
    });
  }
}
