import { Injectable } from '@angular/core';
import { EnergyCenterDto } from '../../common/models/data-contracts';
import { EnergyCenterInfo } from '../models/energy-center';

@Injectable({
  providedIn: 'root',
})
export class CommonMapService {
  public mapEnergyCenterDto(data: EnergyCenterDto): EnergyCenterInfo {
    return {
      id: data.id,
      name: data.name,
      address: {
        street: data.street + ' ' + data.houseNumber,
        postcode: data.postcode,
        city: data.city,
        state: data.state,
      },
    };
  }
}
