import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PeriodTab } from '../../../common/constants/enum';
import { NbDialogService } from '@nebular/theme';
import { ModalCalendarRangeComponent } from '../modal-calendar-range/modal-calendar-range.component';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../common/classes/base-component';
import {
  PeriodDataNameInfo,
  DateTimeRange,
} from '../../../common/classes/data-charts';
import { DatePlot } from '../../../common/helpers/datePlot';
import { AlertsNavigateService } from '../../services/alerts-navigate.service';

@Component({
  selector: 'app-tabs-period',
  templateUrl: './tabs-period.component.html',
  styleUrls: ['./tabs-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsPeriodComponent extends BaseComponent implements OnInit {
  @Output() timePeriodChanged: EventEmitter<PeriodDataNameInfo> =
    new EventEmitter();
  @Input() periodInfoFromLink: PeriodDataNameInfo;
  @Input() showEntireTime = false;

  public periodTab = PeriodTab;
  public periodGroupValue = this.periodTab.today;
  public timeMenu = [
    'lastHour',
    'today',
    'yesterday',
    'last3Days',
    'week',
    'month',
    'year',
    'entireTime',
    'custom',
  ];
  public timePeriod: DateTimeRange;
  private datePlot = DatePlot;

  constructor(
    private alertsNavigateService: AlertsNavigateService,
    private cd: ChangeDetectorRef,
    private dialogService: NbDialogService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.periodInfoFromLink) {
      this.periodGroupValue = this.periodInfoFromLink.name;
      this.timePeriod = this.periodInfoFromLink.period;
    } else {
      this.timePeriod = this.datePlot.getDateTimeRange(this.periodGroupValue);
    }
    this.timePeriodChanged.emit({
      name: this.periodGroupValue,
      period: this.timePeriod,
    });
  }

  public updatePeriodGroupValue(value): void {
    if (value[0] !== this.periodTab.custom) {
      this.periodGroupValue = value[0];
      this.timePeriod = this.datePlot.getDateTimeRange(this.periodGroupValue);
      this.timePeriodChanged.emit({
        name: this.periodGroupValue,
        period: this.timePeriod,
      });
      this.cd.markForCheck();
    }
  }

  public clickMenu(tab: PeriodTab) {
    if (this.periodInfoFromLink) {
      this.alertsNavigateService.setCurrentTabAlert(null);
    }
    if (tab === this.periodTab.custom) {
      this.openCalendarModal();
    }
  }

  private openCalendarModal(): void {
    const dialog = this.dialogService.open(ModalCalendarRangeComponent, {
      autoFocus: false,
      context: {
        periodGroupValue: this.periodGroupValue,
        timePeriod: this.timePeriod,
      },
    });
    dialog.onClose.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.periodGroupValue = this.periodTab.custom;
        this.cd.markForCheck();
        this.timePeriod = this.prepareTimePeriod(res);
        this.timePeriodChanged.emit({
          name: this.periodGroupValue,
          period: this.timePeriod,
        });
      }
    });
  }

  private prepareTimePeriod(timeInterval: Date[]) {
    const start = new Date(timeInterval[0].getTime());
    let end = new Date(timeInterval[1].getTime());
    const today = new Date();

    if (start === end) {
      start.setHours(start.getHours() - 24);
      start.setSeconds(start.getSeconds() + 1);
    } else {
      end.setHours(end.getHours() + 24);
      end.setSeconds(end.getSeconds() - 1);
    }
    if (end.toDateString() === today.toDateString()) {
      end = new Date();
    }
    return { startDate: start.toISOString(), endDate: end.toISOString() };
  }
}
