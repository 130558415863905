import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../../../models/alert';
import { ViewCell } from 'ng2-smart-table';
import { BaseComponent } from '../../../../common/classes/base-component';
import { UserService } from '../../../../common/services/user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertsTableService } from '../../../services/alerts-table.service';
import { AlertStatus } from '../../../models/alert-status';
import { IconType } from '../../../../common/helpers/icon-type';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent extends BaseComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Input() rowData: Alert;

  public renderValue: number | string;
  public iconType = IconType;
  private colName: string;

  public energyCenterId: string;

  public statuses = [AlertStatus.Unresolved, AlertStatus.Resolved];

  constructor(
    private alertsTableService: AlertsTableService,
    private userService: UserService
  ) {
    super();
    this.userService.currentEnergyCenter$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(energyCenterContext => !!energyCenterContext))
      .subscribe(energyCenterContext => {
        this.energyCenterId = energyCenterContext.id;
      });
  }

  ngOnInit(): void {
    this.colName = this.value.column.id;
    this.renderValue = this.rowData[this.colName];
  }

  onChange(): void {
    if (this.rowData.status === AlertStatus.Resolved) {
      this.alertsTableService.markAlertAsUnresolved(this.rowData);
    } else {
      this.alertsTableService.markAlertAsResolved(this.rowData);
    }
  }
}
