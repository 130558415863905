import { Injectable } from '@angular/core';
import {
  EnergyCenterAvailabilityContextDto,
  EnergyCenterAvailabilityContextDtoPagedResponse,
} from '../../../common/models/data-contracts';
import { EnergyCenterContextsPage } from '../models/energy-center-contexts-page';
import { CommonMapService } from '../../../shared/services/common-map.service';
import { EnergyCenterContextInfo } from '../models/energy-center-context-info';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(private commonMapService: CommonMapService) {}

  public mapEnergyCentersPage(
    data: EnergyCenterAvailabilityContextDtoPagedResponse
  ): EnergyCenterContextsPage {
    return {
      nextPageToken: data.nextPageToken,
      items: data.items.map(item => this.mapEnergyCenterContextInfo(item)),
    };
  }

  public mapEnergyCenterContextInfo(
    data: EnergyCenterAvailabilityContextDto
  ): EnergyCenterContextInfo {
    const energyCenterInfo = this.commonMapService.mapEnergyCenterDto(
      data.energyCenter
    );
    return {
      id: energyCenterInfo.id,
      name: energyCenterInfo.name,
      address: `${energyCenterInfo.address.street}, ${energyCenterInfo.address.postcode} ${energyCenterInfo.address.city}`,
      communityName: data.energyCenter.community.name,
      default: data.default ?? false,
    };
  }
}
