import { PeriodTab } from '../constants/enum';

export class ChartDataPoint {
  name: string;
  time: Date;
  value: number;
  unit: string;
  color: string;
  shown: boolean;
  detailedNames?: string[];
  detailedValues?: number[];
}

export class DataPointsByUnit {
  unit: string;
  points: ChartDataPoint[];
}

export class CheckBoxOptions {
  id: string;
  name: string;
  currentValue: number;
  unit: string;
  color: string;
  shown: boolean;
}

export class PeriodDataNameInfo {
  name: PeriodTab;
  period: DateTimeRange;
}

export class DateTimeRange {
  startDate: string;
  endDate: string;
}
