import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullDash',
})
export class NullDashPipe implements PipeTransform {
  // use in html
  transform(value: number | string): number | string {
    if (value === null || value === undefined) {
      return '-';
    } else {
      return value;
    }
  }
}
