import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ObjectToHttpParams } from '../../../common/helpers/objectToHttpParams';
import { AppConfigService } from '../../../common/services/app-config/app-config.service';
import {
  EnergyCenterAvailabilityContextDto,
  EnergyCenterAvailabilityContextDtoPagedResponse,
} from '../../../common/models/data-contracts';

@Injectable({
  providedIn: 'any',
})
export class EnergyCentersUserContextApiService {
  public objectToHttpParams = ObjectToHttpParams;
  private readonly rootUrl = this.appConfigService.getConfigUrl();

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  energyCentersShortList(): Observable<EnergyCenterAvailabilityContextDto[]> {
    return this.http.get<EnergyCenterAvailabilityContextDto[]>(
      `${this.rootUrl}/EnergyCenterUserContext/TopEnergyCenters`
    );
  }

  energyCentersList(
    limit?: number | 10,
    nextPageToken?: string | null
  ): Observable<EnergyCenterAvailabilityContextDtoPagedResponse> {
    const params = {
      params: this.objectToHttpParams.convert({
        limit: limit,
        nextPageToken: nextPageToken,
      }),
    };
    return this.http.get<EnergyCenterAvailabilityContextDtoPagedResponse>(
      `${this.rootUrl}/EnergyCenterUserContext`,
      params
    );
  }

  markEnergyCenterAsDefault(energyCenterId: string): Observable<void> {
    return this.http.post<void>(
      `${this.rootUrl}/EnergyCenterUserContext/${energyCenterId}/DefaultEnergyCenter`,
      {}
    );
  }
}
