import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import {
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbProgressBarModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlowTempComponent } from './components/table-cells/flow-temp/flow-temp.component';
import { ConsumptionProductionComponent } from './components/table-cells/consumption-production/consumption-production.component';
import { ModalSimpleComponent } from './components/modal-simple/modal-simple.component';
import { ErrorInputComponent } from './components/error-input/error-input.component';
import { D3ChartComponent } from './components/d3-chart/d3-chart.component';
import { TabsPeriodComponent } from './components/tabs-period/tabs-period.component';
import { ModalCalendarRangeComponent } from './components/modal-calendar-range/modal-calendar-range.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { JointjsEditorComponent } from './components/jointjs-editor/jointjs-editor.component';
import { TooltipForPlotService } from './services/tooltip-for-plot.service';
import { D3ChartCheckboxesComponent } from './components/d3-chart/d3-chart-checkboxes/d3-chart-checkboxes.component';
import { WidgetEventsComponent } from './components/widget-events/widget-events.component';
import { NullDashPipe } from '../common/pipes/null-dash.pipe';
import { TimeagoModule } from 'ngx-timeago';
import { ClickOutsideDirective } from './directives/click-outside';
import { SpaceUnitPipe } from '../common/pipes/space-unit.pipe';
import { InfoFieldComponent } from './components/info-field/info-field.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { AlertsTableComponent } from './components/alerts-table/alerts-table.component';
import { SeenUnseenComponent } from './components/alerts-table-cells/seen-unseen/seen-unseen.component';
import { AlertCellComponent } from './components/alerts-table-cells/alert-cell/alert-cell.component';
import { StatusComponent } from './components/alerts-table-cells/status/status.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

const modules = [
  InlineSVGModule.forRoot(),
  NbButtonModule,
  NbInputModule,
  NbIconModule,
  NbCardModule,
  NbSpinnerModule,
  NbSelectModule,
  NbTabsetModule,
  NbProgressBarModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  NbButtonGroupModule,
  NbCalendarRangeModule,
  NbCheckboxModule,
  SortablejsModule,
  TimeagoModule,
  NbTooltipModule,
];

const components = [
  FlowTempComponent,
  ConsumptionProductionComponent,
  ModalSimpleComponent,
  ErrorInputComponent,
  D3ChartComponent,
  TabsPeriodComponent,
  ModalCalendarRangeComponent,
  JointjsEditorComponent,
  D3ChartCheckboxesComponent,
  WidgetEventsComponent,
  AlertsTableComponent,
  SeenUnseenComponent,
  AlertCellComponent,
  StatusComponent,
  InfoFieldComponent,
  FormFieldComponent,
];

const pipes = [NullDashPipe, SpaceUnitPipe];

const directives = [ClickOutsideDirective];

@NgModule({
  imports: [...modules, Ng2SmartTableModule],
  exports: [modules, ...components, ...pipes, ...directives],
  declarations: [...components, ...pipes, ...directives],
  providers: [TooltipForPlotService, SpaceUnitPipe],
})
export class SharedModule {}
