import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomPageTitleStrategy extends TitleStrategy {
  constructor(
    private translateService: TranslateService,
    private readonly titleService: Title
  ) {
    super();
  }

  override updateTitle(routerStateSnapshot: RouterStateSnapshot): void {
    const routeTitleItems: string[] = [];

    let routeSnapshot = routerStateSnapshot.root;
    while (routeSnapshot) {
      if (routeSnapshot.routeConfig?.title) {
        routeTitleItems.push(
          typeof routeSnapshot.routeConfig.title === 'string'
            ? this.translateService.instant(
                this.getResolvedTitleForRoute(routeSnapshot)
              )
            : this.getResolvedTitleForRoute(routeSnapshot)
        );
      }

      routeSnapshot = routeSnapshot.firstChild;
    }

    this.titleService.setTitle(routeTitleItems.reverse().join(' - '));
  }
}
