<div class="checkboxOptions">
  <div class="title" *ngIf="!hideCommonValues && checkboxOptions.length > 0">
    {{ 'common.currently' | translate }}:
  </div>
  <ng-container *ngIf="!arrayTwoInColumn; else arrayDistrictTemplate">
    <div class="optionsWrap">
      <div class="optionInner" *ngFor="let option of checkboxOptions">
        <ng-template
          *ngTemplateOutlet="
            checkBoxTemp;
            context: { $implicit: { option: option } }
          "></ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #arrayDistrictTemplate>
    <div class="optionsWrap max-width-100 opacity0" #optionsWrap>
      <div class="grid-wrapper" #wrapper>
        <div style="display: inline-block; min-width: 50%">
          <div class="grid" #grid>
            <div *ngFor="let items of arrayTwoInColumn">
              <div
                class="optionInner"
                *ngFor="let option of checkboxFilterDistrict(items)">
                <ng-template
                  *ngTemplateOutlet="
                    checkBoxTemp;
                    context: { $implicit: { option: option } }
                  "></ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="optionInner marginToPadding"
        *ngFor="let option of checkboxAcceptDistricts(); index as i"
        [ngStyle]="{ width: arrayWidthCheckbox[i] + 'px' }">
        <ng-template
          *ngTemplateOutlet="
            checkBoxTemp;
            context: { $implicit: { option: option } }
          "></ng-template>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #checkBoxTemp let-data>
  <label *ngIf="data.option">
    <input
      class="custom-checkbox"
      type="checkbox"
      [checked]="data.option.shown"
      [disabled]="disabledCheckbox(data.option.id)"
      (change)="switchPlot(data.option.id, $event)" />
    <div class="afterInput">
      <div
        class="imitCheckbox"
        [ngStyle]="{
          'border-color': data.option.color,
          'background-color': data.option.color
        }"></div>
      <div class="name">{{ data.option.name }}</div>
      <ng-container *ngIf="!hideCommonValues">
        <div class="value" *ngIf="data.option.unit">
          {{ data.option.currentValue | number: '1.0-1' | nullDash }}
          <span>{{ data.option.unit | spaceUnit }}</span>
        </div>
      </ng-container>
    </div>
  </label>
</ng-template>
