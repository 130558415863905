import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { House } from '../../../../modules/energy-center-modules/residences/models/house';

@Component({
  selector: 'app-forward-flow-temp',
  templateUrl: './flow-temp.component.html',
  styleUrls: ['./flow-temp.component.scss'],
})
export class FlowTempComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Input() rowData: House;
  public renderValue: number | string;
  public waterTempTarget: number | string;
  private colName: string;

  ngOnInit(): void {
    this.colName = this.value.column.id;
    this.renderValue = this.rowData[this.colName];
    this.waterTempTarget = this.rowData['waterTempTarget'];
  }

  public isCold(): boolean {
    return this.colName === 'retFlow';
  }

  public isEmpty(): boolean {
    return !this.rowData[this.colName];
  }
}
