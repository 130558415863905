import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AlertsApiService } from '../../services/alerts-api.service';
import { BaseComponent } from '../../../common/classes/base-component';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../common/services/user.service';
import { DateTimeRange } from '../../../common/classes/data-charts';
import { AlertsMapService } from '../../services/alerts-map.service';
import { Alert } from '../../models/alert';
import { ModalSimpleComponent } from '../modal-simple/modal-simple.component';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../../common/services/app-config/app-config.service';
import { PeriodTab } from '../../../common/constants/enum';
import { Router } from '@angular/router';
import { AlertsNavigateService } from '../../services/alerts-navigate.service';
import { IconType } from '../../../common/helpers/icon-type';
import { AlertScope } from '../../../common/models/data-contracts';

@Component({
  selector: 'app-widget-events',
  templateUrl: './widget-events.component.html',
  styleUrls: ['./widget-events.component.scss'],
})
export class WidgetEventsComponent
  extends BaseComponent
  implements OnChanges, OnDestroy
{
  @Input() timePeriod: DateTimeRange;
  @Input() timePeriodName: PeriodTab;

  @ViewChildren('templateBody') templateBody: QueryList<ElementRef>;

  public alerts: Alert[];
  public energyOperatorId: string;
  public energyCenterId: string;
  public loading = true;
  public iconType = IconType;
  private updateTime: number;
  private timerId: any;

  constructor(
    private alertsApiService: AlertsApiService, // replace
    private userService: UserService,
    private appConfigService: AppConfigService,
    private dialogService: NbDialogService,
    private translateService: TranslateService,
    private router: Router,
    private alertsNavigateService: AlertsNavigateService,
    private mapService: AlertsMapService // replace
  ) {
    super();
    this.updateTime = this.appConfigService.getTimeUpdateRequests();
    this.userService.currentEnergyCenter$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(energyCenterContext => !!energyCenterContext))
      .subscribe(energyCenterContext => {
        this.energyOperatorId = energyCenterContext.community.energyOperator.id;
        this.energyCenterId = energyCenterContext.id;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timePeriodName) {
      this.getWidgetAlerts();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  public goToAlerts() {
    this.alertsNavigateService.setCurrentTabAlert({
      name: this.timePeriodName,
      period: this.timePeriod,
    });
    this.router
      .navigate([`/pages/energycenters/${this.energyCenterId}/alerts`])
      .then();
  }

  private getWidgetAlerts(isUpdate?: boolean) {
    if (!isUpdate) {
      this.loading = true;
    }
    this.alertsApiService
      .unseenAlertList(
        this.energyOperatorId,
        AlertScope.Value1,
        this.energyCenterId,
        null,
        this.timePeriod.startDate,
        this.timePeriod.endDate,
        10
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          const alertsWithToken = this.mapService.mapAlertDtoPagedResponse(res);
          this.alerts = alertsWithToken.items;
          this.loading = false;
          this.updateTimer();
        },
        () => {
          this.loading = false;
        }
      );
  }

  private removeFromList(alert: Alert, index: number) {
    const dialog = this.dialogService.open(ModalSimpleComponent, {
      autoFocus: false,
      context: {
        title: this.translateService.instant('alerts.remove-modal.title'),
        message: this.translateService.instant('alerts.remove-modal.message'),
        body: this.templateBody.get(index).nativeElement.outerHTML,
        okText: this.translateService.instant('alerts.remove-modal.dismiss'),
      },
    });
    dialog.onClose.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.alertsApiService
          .markAlertAsSeen(this.energyOperatorId, { alertIds: [alert.id] })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.getWidgetAlerts();
          });
      }
    });
  }

  private updateTimer(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(
      () => this.getWidgetAlerts(true),
      this.updateTime
    );
  }
}
