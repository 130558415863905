import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  AlertDtoPagedResponse,
  AlertScope,
  AlertsIdDto,
} from '../../common/models/data-contracts';
import { ObjectToHttpParams } from '../../common/helpers/objectToHttpParams';
import { AppConfigService } from '../../common/services/app-config/app-config.service';

@Injectable({
  providedIn: 'any',
})
export class AlertsApiService {
  public objectToHttpParams = ObjectToHttpParams;
  private readonly energyCenterUrl: string;

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.energyCenterUrl = `${this.appConfigService.getConfigUrl()}/EnergyOperator`;
  }

  unseenAlertList(
    energyOperatorId: string,
    alertScope?: AlertScope,
    energyCenterId?: string,
    deviceId?: string,
    startDate?: string,
    endDate?: string,
    limit?: number | 10,
    nextPageToken?: string | null
  ): Observable<AlertDtoPagedResponse> {
    const params = {
      params: this.objectToHttpParams.convert({
        scopeType: alertScope ?? null,
        energyCenterId: energyCenterId ?? null,
        deviceId: deviceId ?? null,
        startDate: startDate,
        endDate: endDate,
        onlyUnseen: true,
        limit: limit,
        nextPageToken: nextPageToken,
      }),
    };
    return this.http.get<AlertDtoPagedResponse>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert`,
      params
    );
  }

  alertList(
    energyOperatorId: string,
    alertScope?: AlertScope,
    energyCenterId?: string,
    deviceId?: string,
    startDate?: string,
    endDate?: string,
    onlyUnseen?: boolean | false,
    limit?: number | 10,
    nextPageToken?: string | null
  ): Observable<AlertDtoPagedResponse> {
    const params = {
      params: this.objectToHttpParams.convert({
        scopeType: alertScope ?? null,
        energyCenterId: energyCenterId ?? null,
        deviceId: deviceId ?? null,
        startDate: startDate,
        endDate: endDate,
        onlyUnseen: onlyUnseen,
        limit: limit,
        nextPageToken: nextPageToken,
      }),
    };
    return this.http.get<AlertDtoPagedResponse>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert`,
      params
    );
  }

  markAlertAsSeen(
    energyOperatorId: string,
    data: AlertsIdDto
  ): Observable<void> {
    return this.http.post<void>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert/markasseen`,
      data
    );
  }

  markAlertAsUnseen(
    energyOperatorId: string,
    data: AlertsIdDto
  ): Observable<void> {
    return this.http.post<void>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert/markasunseen`,
      data
    );
  }

  markAlertAsResolved(
    energyOperatorId: string,
    data: AlertsIdDto
  ): Observable<void> {
    return this.http.post<void>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert/markasresolved`,
      data
    );
  }

  markAlertAsUnresolved(
    energyOperatorId: string,
    data: AlertsIdDto
  ): Observable<void> {
    return this.http.post<void>(
      `${this.energyCenterUrl}/${energyOperatorId}/alert/markasunresolved`,
      data
    );
  }
}
