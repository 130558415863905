<div class="status-wrap">
  <span
    class="status-icon"
    [inlineSVG]="iconType.statusIcon(rowData.status)"></span>
  <nb-select
    [(selected)]="renderValue"
    (selectedChange)="onChange()"
    size="small">
    <nb-option value="unresolved">{{
      'alerts.table.unresolved' | translate
    }}</nb-option>
    <nb-option value="resolved">{{
      'alerts.table.resolved' | translate
    }}</nb-option>
  </nb-select>
</div>
