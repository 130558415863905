import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { NoteType } from '../../common/models/note-type';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  _translateService: TranslateService;

  constructor(
    private toastrService: NbToastrService,
    private readonly injector: Injector
  ) {}

  public showError(errorResponse: HttpErrorResponse): void {
    let text: string | undefined;
    if (errorResponse.error) {
      if (errorResponse.error.detail) {
        text = errorResponse.error.detail;
      } else if (errorResponse.error.title) {
        text = errorResponse.error.title;
      }
    }
    text = text ?? this.translate('errors.internal-server-error');
    this.warn(text);
  }
  translate(untranslatedText: string) {
    if (!this._translateService) {
      this._translateService = this.injector.get(TranslateService);
    }
    return this._translateService.instant(untranslatedText);
  }

  success(successText: string) {
    this.toastrService.success(successText);
  }

  warn(text: any) {
    this.toastrService.show(
      text,
      this.translate('notification.title.' + NoteType.Danger),
      { status: NoteType.Danger }
    );
  }

  public successToastr(): void {
    const translateService = this.injector.get(TranslateService);
    this.toastrService.show(
      translateService.instant('notification.message.requestSent'),
      translateService.instant('notification.title.' + NoteType.Success),
      { status: NoteType.Success }
    );
  }
}
