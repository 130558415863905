import * as d3 from 'd3';

export class Constants {
  public static ICON_URL = '/assets/icons/';

  public static MAIN_BREAKPOINT = 1600;
  public static HIGH_BREAKPOINT = 1920;

  public static PARSE_TIME_D3 = d3.utcParse('%Y-%m-%dT%H:%M:%S');

  public static TOKEN_LS = 'AuthToken';
}
