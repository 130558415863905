import { Constants } from '../constants/constants';
import { AlertStatus } from '../../shared/models/alert-status';

export class IconType {
  static statusIcon(status: AlertStatus): string {
    const startUrl = Constants.ICON_URL;
    const endUrl =
      status === AlertStatus.Resolved ? 'resolved.svg' : 'unresolved.svg';
    return startUrl + endUrl;
  }

  static mapComponentTypeToIcon(componentType: number): string {
    switch (componentType) {
      case 12:
        return 'chp';
      case 13:
        return 'gas';
      default:
        return 'alert';
    }
  }

  static mapSeverity(severirty: number): string {
    switch (severirty) {
      case 1:
        return 'error';
      case 2:
        return 'warning';
      case 3:
        return 'info';
    }
  }

  static iconFullPath(componentType: number) {
    return (
      Constants.ICON_URL +
      'panel-diagrams/' +
      this.mapComponentTypeToIcon(componentType) +
      '.svg'
    );
  }
}
