import { Component, Input, OnInit } from '@angular/core';
import { House } from '../../../../modules/energy-center-modules/residences/models/house';
import { ViewCell } from 'ng2-smart-table';
import { Constants } from '../../../../common/constants/constants';

@Component({
  selector: 'app-consumption-production',
  templateUrl: './consumption-production.component.html',
  styleUrls: ['./consumption-production.component.scss'],
})
export class ConsumptionProductionComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Input() rowData: House;
  public renderValue: number | string;
  public paramProductionValue: number | string;
  public icon = '/assets/icons/heat.svg';
  private colName: string;

  ngOnInit(): void {
    this.colName = this.value.column.id;
    this.renderValue = this.rowData[this.colName];
    this.productionValue();
  }

  public isEmpty(value: number | string): boolean {
    return value === null;
  }

  public iconType(): string {
    const startUrl = Constants.ICON_URL;
    const endUrl =
      this.colName === 'electricityConsumption'
        ? 'electricity.svg'
        : 'heat.svg';
    return startUrl + endUrl;
  }

  private productionValue(): void {
    if (this.colName === 'electricityConsumption') {
      this.paramProductionValue = this.rowData['electricityProduction'];
    } else if (this.colName === 'heatConsumption') {
      this.paramProductionValue = this.rowData['heatProduction'];
    }
  }
}
