import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NbContextMenuDirective,
  NbMenuItem,
  NbMenuService,
  NbTrigger,
} from '@nebular/theme';
import { MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants/constants';
import { UserService } from '../../../common/services/user.service';
import { BaseComponent } from '../../../common/classes/base-component';
import { TranslateService } from '@ngx-translate/core';
import { UserInfo } from './models/user';
import { EnergyCentersUserContextApiService } from '../../../modules/energy-centers/services/energy-center-user-context-api.service';
import { MapService } from '../../../modules/energy-centers/services/map.service';
import { EnergyCenterContextInfo } from '../../../modules/energy-centers/models/energy-center-context-info';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(NbContextMenuDirective) communityMenu: NbContextMenuDirective;

  user: UserInfo;
  communityName: string;
  energyCenterName: string;

  private energyCenterContexts: EnergyCenterContextInfo[] = [];
  private energyCenterId: string;

  communityMenuItems: { link: string; title: string }[] = [
    {
      title: this.translateService.instant('menu.energy-centers'),
      link: `/pages/energycenters`,
    },
  ];

  userMenu = [
    {
      title: this.translateService.instant('menu.profile'),
      link: '/pages/profile',
    },
    {
      title: this.translateService.instant('menu.log-out'),
    },
  ];

  items: NbMenuItem[];

  public shownMobMainMenu = false;

  @ViewChild('mainMenu') mainMenu: ElementRef;

  constructor(
    private translateService: TranslateService,
    private msalService: MsalService,
    private nbMenuService: NbMenuService,
    private userService: UserService,
    private energyCentersUserContextApiService: EnergyCentersUserContextApiService,
    private energyCentersUserContextMapService: MapService
  ) {
    super();
  }

  ngOnInit() {
    this.userService.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (user) {
          this.user = {
            fullName: `${user.firstName} ${user.lastName}`,
            pictureUrl: 'assets/images/nick.png',
          };

          if (user.energyCenterContext?.hasAccessToSeveralEnergyCenters) {
            this.energyCentersUserContextApiService
              .energyCentersShortList()
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                this.energyCenterContexts =
                  data.map(dto =>
                    this.energyCentersUserContextMapService.mapEnergyCenterContextInfo(
                      dto
                    )
                  ) ?? [];
                this.resetEnergyCentersMenuItems();
              });
          }
        }
      });

    this.userService.currentEnergyCenter$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(energyCenterContext => !!energyCenterContext))
      .subscribe(energyCenterContext => {
        this.energyCenterName = energyCenterContext.name;
        this.communityName = energyCenterContext.community.name;

        this.energyCenterId = energyCenterContext.id;
        this.resetUserMenuItems();
        this.resetEnergyCentersMenuItems();
      });

    this.handleLogout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openMenuMobile(): void {
    console.log(this.mainMenu.nativeElement);
    if (this.shownMobMainMenu) {
      this.mainMenu.nativeElement.classList.remove('showMob');
    } else {
      this.mainMenu.nativeElement.classList.add('showMob');
    }
    this.shownMobMainMenu = !this.shownMobMainMenu;
  }

  public onOutsideMenu(): void {
    this.mainMenu.nativeElement.classList.remove('showMob');
    this.shownMobMainMenu = false;
  }

  private resetEnergyCentersMenuItems() {
    const groupedByCommunityName: {
      [communityName: string]: EnergyCenterContextInfo[];
    } = this.energyCenterContexts
      .filter(ecc => this.energyCenterId && ecc.id != this.energyCenterId)
      .reduce((acc, currentItem) => {
        const communityName = currentItem.communityName;
        if (!acc[communityName]) {
          acc[communityName] = [];
        }
        acc[communityName].push(currentItem);
        return acc;
      }, {});

    this.communityMenuItems = [];

    Object.keys(groupedByCommunityName).forEach(communityName => {
      if (groupedByCommunityName[communityName].length == 1) {
        const energyCenterContext = groupedByCommunityName[communityName][0];
        this.communityMenuItems.push({
          title: energyCenterContext.communityName,
          link: `/pages/energycenters/${energyCenterContext.id}`,
        });
      } else {
        groupedByCommunityName[communityName].forEach(energyCenterContext => {
          this.communityMenuItems.push({
            title: `${energyCenterContext.communityName}(${energyCenterContext.name})`,
            link: `/pages/energycenters/${energyCenterContext.id}`,
          });
        });
      }
    });

    this.communityMenuItems.push({
      title: this.translateService.instant('menu.energy-centers'),
      link: `/pages/energycenters`,
    });

    if (this.communityMenu) {
      this.communityMenu.trigger =
        this.communityMenuItems.length > 1 ? NbTrigger.CLICK : NbTrigger.NOOP;
    }
  }

  private resetUserMenuItems() {
    this.items = [
      {
        title: 'menu.dashboard',
        link: `/pages/energycenters/${this.energyCenterId}/dashboard`,
      },
      {
        title: 'menu.statistics',
        link: `/pages/energycenters/${this.energyCenterId}/statistics`,
      },
      {
        title: 'menu.devices',
        link: `/pages/energycenters/${this.energyCenterId}/devices`,
      },
      {
        title: 'menu.residences',
        link: `/pages/energycenters/${this.energyCenterId}/residences`,
      },
      {
        title: 'menu.alerts',
        link: `/pages/energycenters/${this.energyCenterId}/alerts`,
      },
    ];
  }

  private handleLogout() {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(res => res.tag === 'user-menu'),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        if (res.item.title === this.translateService.instant('menu.log-out')) {
          this.msalService.logout();
          localStorage.removeItem(Constants.TOKEN_LS);
        }
      });
  }
}
