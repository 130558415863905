import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../common/classes/base-component';
import { SettingsTableAlerts } from '../../constants/settings-table-alerts';
import { AlertsTableService } from '../../services/alerts-table.service';

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent extends BaseComponent implements OnInit {
  public settings;
  public source = this.alertsTableService.source;

  get isEmpty(): boolean {
    return !this.source.count();
  }

  get isLoading(): boolean {
    return this.alertsTableService.isLoading();
  }

  constructor(
    private settingsTableAlerts: SettingsTableAlerts,
    private alertsTableService: AlertsTableService
  ) {
    super();
  }

  ngOnInit(): void {
    this.settings = { ...this.settingsTableAlerts.settings };

    if (this.alertsTableService.hideComponentColumn) {
      delete this.settingsTableAlerts.settings.columns.componentName;
    }
  }
}
