export enum PeriodTab {
  lastHour = 'lastHour',
  today = 'today',
  yesterday = 'yesterday',
  last3Days = 'last3Days',
  week = 'week',
  month = 'month',
  year = 'year',
  entireTime = 'entireTime',
  custom = 'custom',
}

export enum ComponentStatusType {
  active = 'Active',
  error = 'Error',
  switchedOff = 'Switched off',
}
